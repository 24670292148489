import React, { useState } from 'react';
import { Avatar, Modal, Checkbox, Button, Badge, Tag, Empty, Spin } from 'antd';
import IconFont from "../../components/iconFont/IconFont";
import { CustomDateFNS } from "../../components";
import './AdicionarVendedorFila.css';


const AdicionarVendedorFila = ({ visible, onCancel, onSubmit, data }) => {
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(0);

    const handleOk = async () => {
        setLoading(true);
        setCurrent(1);
        await onSubmit(checkedList);
    };

    const handleCancel = () => {
        onCancel();
        setCheckedList([]);
        setCheckAll(false);
        setLoading(false);
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? data.map((item) => item.id) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const onChange = (checkedList) => {
        setCheckedList(checkedList);
        setIndeterminate(!!checkedList.length && checkedList.length < data.length);
        setCheckAll(checkedList.length === data.length);
    };

    function contarVendedores() {
        return data.length >= 1 ? data.length : 'Nenhuma';
    }

    function contarVendedoresStr() {
        return data.length <= 1 ? ' pessoa ausente' : ' pessoas ausentes'
    }

    return (
        <div>
            <Modal
                maskTransitionName="slide-down"
                bodyStyle={{ height: 300 }}
                open={visible}
                onCancel={handleCancel}
                onOk={handleOk}
                title="Quem vai entrar na fila?"
                destroyOnClose
                wrapClassName="rounded-modal"
                maskClosable={false}
                closable={!loading}
                footer={[
                    <div>
                        <div>
                            {current === 0 ? (
                                <label className='totalVendedores'><strong>{contarVendedores()}</strong>{contarVendedoresStr()}</label>
                            ) : ('')}
                        </div>
                        <Button
                            key="back"
                            onClick={handleCancel}
                            disabled={loading}
                        >
                            Cancelar
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            disabled={checkedList.length === 0 || loading}
                            onClick={handleOk}
                        >
                            {loading ? 'Adicionando...' : 'Adicionar'}
                        </Button>
                    </div>
                ]}

            >

                {current === 0 ? (
                    <div>
                        {data.length > 0 ? (
                            <div>
                                <br></br>
                                <Checkbox
                                    indeterminate={indeterminate}
                                    onChange={onCheckAllChange}
                                    checked={checkAll}
                                    className='checkBoxAdicionarTodosVendedores'
                                >
                                    Selecionar todos
                                </Checkbox>
                                <hr className='dividerListaAddVendedores' />
                                <div className='divListaAddVendedores'>
                                    {data.map((item) => (
                                        <Badge
                                            key={item.id}
                                            count={
                                                <div>
                                                    <Tag className={`badge-position badge-${item.status.descricao === 'casa' ? "offline" : "online"}`}>
                                                        <IconFont
                                                            type={item.status.icon}
                                                            size={14}
                                                            className='badge-position-icon-font'
                                                        />
                                                        <label>&nbsp;&nbsp;{item.status.descricao} | <CustomDateFNS date={item.status.data} interval={15}></CustomDateFNS>  </label>
                                                    </Tag>
                                                </div>

                                            }
                                        >
                                            <Checkbox
                                                value={item.id}
                                                checked={checkedList.includes(item.id)}
                                                onChange={(e) => {
                                                    onChange(
                                                        e.target.checked
                                                            ? [...checkedList, item.id]
                                                            : checkedList.filter((id) => id !== item.id)
                                                    );
                                                }}
                                                className='checkBoxAddVendedor'
                                            />

                                            <div className="avatar-with-name">
                                                <Avatar
                                                    src={item.avatar}
                                                    size={52}
                                                    className={`status-${item.status.descricao === 'casa' ? "offline" : "online"}`}
                                                />
                                                <h6>{item.apelido}</h6>
                                            </div>
                                            <br></br>
                                        </Badge>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <Empty description="Não há vendedores a serem adicionados" />
                        )}
                    </div>
                ) : (
                    <div className='divSpinAdicionandoVendedor'>
                        <Spin size="large" />
                        <br></br>
                        <label>Aguarde, estamos adicionando <br></br>os vendedores selecionados!</label>
                    </div>
                )}

            </Modal>
        </div>
    );
};

export default AdicionarVendedorFila;
