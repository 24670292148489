import DataManager from "./dtm";
import { fbDatabase, fbFirestore } from "../firebase/firebase";


class EmpresaDB extends DataManager {

    state = {
        collection: 'empresas',
        orderBy: ['razaoSocial', 'nomeFantasia'], // usado na classe pai
    }

    async getByGestorIdCustom(gestorId, orderBy) {
        const snapshot = await fbDatabase.collection(this.state.collection)
            .where("gestorId", "==", gestorId)
            .orderBy(orderBy)
            .get();

        /** X-TUDO */
        let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmEmpresaJSX-EmpresaDB-getByGestorIdCustom');
        xRef.set({
            collection: 'empresas',
            lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
            count: fbFirestore.FieldValue.increment(snapshot.size),
            lastIncrement: snapshot.size
        }, { merge: true }).catch(error => console.error(error));

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            razaoSocial: item.data().razaoSocial,
            login: item.data().login,
        }));
        return data;
    }

    async getByCNPJ(cnpj) {
        const data = await this.getByParamValue('cnpj', cnpj);
        return data;
    }

    async getByLogin(login) {
        const data = await this.getByParamValue('login.login', login);
        return data;
    }

    async getByEmail(email) {
        const data = await this.getByParamValue('email', email);
        return data;
    }
}

const empresaDB = new EmpresaDB();
export default empresaDB;