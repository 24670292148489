import React, { Component } from "react";
import ChildComponent from "./CustomDateFNS.component";
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";


class CustomDateFNS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: "",
      dateRelative: "",
      classCss: "",
    };
  }

  _update() {
    this.setState({
      dateRelative: formatDistanceToNow(this.props.date, {
        // addSuffix: true,
        locale: ptBR,
      }),
      classCss: this.props.classCss,
    });
  }

  _tick() {
    this._update();
  }

  _setInterval(interval) {
    this._update();

    interval = interval || 1;
    this.intervalID = setInterval(() => this._tick(), interval * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentDidMount() {
    const { interval } = this.props;
    this._setInterval(interval);
  }

  componentDidUpdate(prevProps) {
    if (this.props.date && prevProps.date !== this.props.date) {
      this._update();
    }
  }

  render() {
    const { dateRelative, classCss } = this.state;
    return <ChildComponent date={dateRelative} className={classCss}/>;
  }
}

export default CustomDateFNS;
