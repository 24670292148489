import { ROUTER_API_FILA } from '../../firebase/firebase';
import { firebaseImpl } from '../../firebase/firebase';
import axios from 'axios';

const instance = axios.create({
  baseURL: ROUTER_API_FILA,
  headers: {
    'Content-Type': 'application/json',
    'authUserToken': true,
  },
});

const event = new Event('tokenAvailable');

firebaseImpl
  .auth()
  .onAuthStateChanged(async user => {
    if (user) {
      const token = await user.getIdToken(true);
      instance.defaults.headers['authorization'] = `bearer ${token}`;
      document.dispatchEvent(event);
    };
  });

export default instance;
