import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';

function Guide (props) {
  const [runGuide, setRunGuide] = useState(false);

  useEffect(() => {
    setRunGuide(true);
  }, []);

  const steps = [
    {
      target: '.fila-menu',
      title: 'Menu de Acesso Rápido',
      content: 'Aqui você verá algumas informações da sua loja e poderá acessar outras opções.',
      disableBeacon: true,
    },
    {
      target: '.lista-vendedores-content',
      title: 'Fila de Atendimento',
      content: 'Aqui listamos os vendedores que estão disponíveis para atendimento ou que já estejam atendendo clientes.',
    },
    {
      target: '.fila-footer',
      title: 'Vendedores Ausentes',
      content: 'Todos os vendedores da sua loja, que não estivem em atendimento, aparecerão aqui. Quando todos estiverem em atendimento, esta área será ocultada para facilitar a utilização da fila. Para inserir vendedores na fila de atendimento, basta arrastar o vendedor para dentro da área de atendimento.',
    },
    {
      target: '.fila-footer-botoes button',
      title: 'Navegação dos Vendedores Ausentes',
      content: 'Se a loja possuir mais vendedores do que o espaço disponível, você poderá navegar entre eles utilizando estes botões de navegação.',
    },
    {
      target: '.fila-footer-carousel-card',
      title: 'Vendedor Ausente',
      content: 'Aqui você verá o nome do vendedor ausente e o tempo que está fora. Para inserir alguém na fila de atendimento, basta arrastar o card para dentro da área de atendimento.',
    },
    // {
    //   target: '.layout-footer-fixo',
    //   title: 'Qualidade da sua Internet',
    //   content: 'Aqui você verá a qualidade da sua internet. Se estiver baixa, você poderá ter problemas com a utilização do sistema.', 
    // },
  ];

  const locale = {
    back: 'Anterior',
    next: 'Próximo',
    skip: 'Pular',
    last: 'Finalizar',
  };

  const styles = {
    options: {
      zIndex: 10000,
    },
    buttonBack: {
      color: 'white',
      backgroundColor: '#40A9FF',
    },
    buttonNext: {
      color: 'white',
      backgroundColor: '#40a9ff',
    },
    buttonSkip: {
      color: 'white',
      backgroundColor: '#40a9ff',
    }
  };  


  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunGuide(false);
      if (props.callbackGuide) {
        props.callbackGuide();
      }
    }
  };

  return (
    <Joyride
      autoStart={true}
      startAt={0}
      continuous
      disableBeacon={true}
      run={runGuide}
      steps={steps}
      callback={handleJoyrideCallback}
      locale={locale}
      styles={styles}
      scrollToFirstStep
      showSkipButton
    />
  );
};

export default Guide;
