import React from 'react';
import classNames from 'classnames';
import './icon.less';
import '../../assets/icons/style.css'


const IconFont = ({ type, active, className, size = 16, style, ...rest }) => (
    <i
        {...rest}
        style={{ fontSize: size, ...style }}
        className={classNames(
            'icon',
            {
                'icon_active': active,
                [`icon-${type}`]: type,
            },
            className
        )}
    />
);

export default IconFont;