import React, { useState, useEffect, useRef } from 'react';
import { Avatar, List, Button, Modal, Badge, Empty } from 'antd';
import { PlusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDrop } from 'react-dnd';
import AdicionarVendedorFilaWrapper from './AdicionarVendedorFilaWrapper';
import SairDaFila from './SairFila/SairFila';
import DiarioVendedor from '../../pages/diarioVendedor/DiarioVendedor';
import EncerrarAtendimento from './EncerrarAtendimento/EncerrarAtendimento';
import BotaoAcaoFlutuante from '../BotaoAcao/BotaoAcao';
import MenuMaisOpcoes from './MenuMaisOpcoes/MenuMaisOpcoes';
import {
  ausentarVendedor,
  editAtendimento,
  entrarNaFila,
  formatarNome,
  iniciarAtendimento,
} from '../../pages/fila/Functions-Fila';
import './VendedoresDisponiveis.css';
import IconFont from '../iconFont/IconFont';
import CustomDateFNS from '../custom-date-fns/CustomDateFNS.container';

const { confirm } = Modal;

function VendedoresDisponiveis(props) {
  const [tipoEncerramento, setTipoEncerramento] = useState('');
  const [mostrarSaidaVendedor, setMostrarSaidaVendedor] = useState(false);
  const [vendedor, setVendedor] = useState({});
  const [mostrarAdicionarVendedor, setMostrarAdicionarVendedor] = useState(false);
  const [diarioVendedor, setDiarioVendedor] = useState({ id: undefined });
  const [mostrarEncerramento, setMostrarEncerramento] = useState(false);
  const [ultimoItemIndex, setUltimoItemIndex] = useState(null);

  // Usando useRef para garantir que o componente está montado
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    if (props.state.dataFila.length > 0 && isMounted.current) {
      const ultimoItemIndex = props.state.dataFila.length - 1;
      setUltimoItemIndex(ultimoItemIndex);
    }

    return () => {
      isMounted.current = false; // Marca o componente como desmontado
    };
  }, [props.state.dataFila]);

  function adicionarAtendimento(obj) {
    const args = {
      self: props.self,
      user: obj.user,
    };

    confirm({
      title: 'Adicionar atendimento',
      icon: <ExclamationCircleOutlined />,
      content: `Adicionar atendimento simultâneo para ${obj.user.apelido}?`,
      onOk() {
        if (isMounted.current) {
          editAtendimento(args);
        }
      },
    });
  }

  function btnIniciarAtendimentoClick(obj) {
    if (isMounted.current) {
      iniciarAtendimento(props.self, obj.user);
    }
  }

  function fecharEncerrarAtendimento() {
    setMostrarEncerramento(false);
  }

  function abrirModalAdicionarVendedor() {
    if (props.state.dataFila.length === 0 && isMounted.current) {
      setMostrarAdicionarVendedor(true);
    }
  }

  const onMenuMaisOpcoesClick = (item, user) => {
    if (item === 'sair') {
      setVendedor(user);
      setMostrarSaidaVendedor(true);
    } else if (item === 'ranking') {
      setDiarioVendedor({
        id: user.id,
        nome: user.nome,
        cpf: user.cpf,
        empresaId: props.self.state.user.id,
        visible: true,
        vendedores: props.self.state.dataFila.concat(props.self.state.dataAusente),
      });
    }
  };

  const onBotaoAcaoClick = (tipoAcao, usuario) => {
    if (tipoAcao === 'Adicionar Atendimento') {
      adicionarAtendimento({ user: usuario });
      return;
    }

    setTipoEncerramento(tipoAcao);
    setVendedor(usuario);
    setMostrarEncerramento(true);
  };

  const [, drop] = useDrop({
    accept: 'card',
    drop: async (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) return;

      const cards = props.state.dataAusente.slice();
      const { index: dragIndex } = item;
      const { index: hoverIndex } = monitor.getItem();
      const draggedCard = cards[dragIndex];
      cards.splice(dragIndex, 1);
      cards.splice(hoverIndex, 0, draggedCard);

      await entrarNaFila(props.self, draggedCard, props.state.dataFila, props.state.dataAusente);

      if (props.self.state.config.exibirDesempenhoVendas && isMounted.current) {
        setDiarioVendedor({
          id: draggedCard.id,
          nome: draggedCard.nome,
          cpf: draggedCard.cpf,
          empresaId: props.self.state.user.id,
          visible: true,
          vendedores: props.self.state.dataFila.concat(props.state.dataAusente),
        });
      }
    },
    cancel: (props, monitor) => monitor.getItem(),
  });

  return (
    <div className='fila-vendedores-disponiveis-container'>
      {mostrarAdicionarVendedor && (
        <AdicionarVendedorFilaWrapper
          mostrarAdicionarVendedor={mostrarAdicionarVendedor}
          setMostrarAdicionarVendedor={setMostrarAdicionarVendedor}
          dataFila={props.state.dataFila}
          dataAusente={props.state.dataAusente}
        />
      )}

      {mostrarEncerramento && (
        <EncerrarAtendimento
          self={props.self}
          visible={true}
          vendedor={vendedor}
          motivos={props.state.motivos}
          onCancel={fecharEncerrarAtendimento}
          tipoEncerramento={tipoEncerramento}
        />
      )}

      {diarioVendedor.id && (
        <DiarioVendedor
          id={diarioVendedor.id}
          nome={diarioVendedor.nome}
          empresaId={diarioVendedor.empresaId}
          visible={diarioVendedor.visible}
          vendedores={diarioVendedor.vendedores}
          exigirSenhaDesempenhoVendas={props.self.state.config.exigirSenhaDesempenhoVendas}
          cpf={diarioVendedor.cpf}
          onClose={() => {
            setDiarioVendedor({
              id: '',
              nome: '',
              empresaId: '',
              visible: false,
            });
          }}
        />
      )}

      <SairDaFila
        visible={mostrarSaidaVendedor}
        destroyOnClose={true}
        vendedor={vendedor}
        onSubmit={async (razao) => {
          return new Promise(async (resolve) => {
            await ausentarVendedor(props.self, vendedor, razao.type, razao.value);
            resolve(true);
            setMostrarSaidaVendedor(false);
          });
        }}
        onCancel={() => setMostrarSaidaVendedor(false)}
      />

      <div
        className='fila-vendedores-disponiveis'
        ref={drop}
        onClick={(event) => {
          event.stopPropagation();
          abrirModalAdicionarVendedor();
        }}
      >
        <div className='lista-vendedores-content'>
          <List
            className='lista-vendedores-diposniveis'
            loading={props.state.loading || props.state.aguardarProcessamentoFila}
            locale={{
              emptyText: (
                <Empty
                  imageStyle={{ height: 48 }}
                  className='adicionar-vendedores-empty-container'
                  image={<PlusCircleOutlined style={{ fontSize: 48 }} />}
                  description={
                    <div>
                      <strong className='adicionar-vendedores-empty-container-strong'>
                        Entrar na fila de atendimento
                      </strong>
                      <br />
                      <span className='adicionar-vendedores-empty-container-description'>
                        Toque para adicionar ou arraste alguém na lista abaixo
                        para colocá-lo na fila de atendimento
                      </span>
                    </div>
                  }
                />
              ),
            }}
            dataSource={props.state.dataFila}
            renderItem={(item, index) => (
              <List.Item
                key={item.id}
                style={{ border: 'ridge 1px', backgroundColor: item.backgroundColor }}
                className={index === ultimoItemIndex ? 'ultimo-item' : ''}
              >
                <List.Item.Meta
                  avatar={<Avatar size={52} src={item.avatar} />}
                  className='list-item-meta-avatar'
                  title={formatarNome(item.apelido || item.nome)}
                  description={
                    <div>
                      <p>{item.status.descricao}</p>
                      <Badge
                        className='sellerBadge'
                        count={<IconFont type={item.status.icon} size={20} />}
                      >
                        <CustomDateFNS date={item.status.data} interval={15} />
                      </Badge>
                    </div>
                  }
                />
                {!item.status.emAtendimento && (
                  <div className='sellerButtons'>
                    <Button
                      className='btnIniciarAtendimento'
                      type='primary'
                      disabled={props.state.loading}
                      onClick={() => btnIniciarAtendimentoClick({ user: item })}
                    >
                      Atender
                    </Button>

                    <MenuMaisOpcoes onItemClick={onMenuMaisOpcoesClick} user={item} />
                  </div>
                )}
                {item.status.emAtendimento && (
                  <div className='sellerButtons'>
                    <BotaoAcaoFlutuante user={item} onBotaoAcaoClick={onBotaoAcaoClick} />
                  </div>
                )}
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default VendedoresDisponiveis;
