import React, { useState } from 'react';
import {
  Modal,
  Button,
  Input,
  message,
  List,
  Empty,
  Avatar,
  Badge,
} from 'antd';
import {
  formatarNome,
  retornarGerente,
} from '../../pages/fila/Functions-Fila';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
} from '@ant-design/icons';
import IconFont from '../iconFont/IconFont';
import './Configuracoes.css';
import { fbDatabase } from '../../firebase/firebase';
import { getGestorId } from '../auth/auth-provider';

const ConfiguracoesFila = ({ visible, onCancel, onSubmit, self, dataFila }) => {
  const Direction = {
    UP: 'up',
    DOWN: 'down',
  };

  const [checkedList, setCheckedList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cpfGerente, setCpfGerente] = useState('');
  const [step, setStep] = useState(0);
  const [labelNextButton, setLabelNextButton] = useState('Próximo');
  const [labelBackButton, setLabelBackButton] = useState('Voltar');
  const [gerente, setGerente] = useState(null);

  const handleOk = async () => {
    setLoading(true);
    if (cpfGerente.length < 6) {
      setLoading(false);
      message.error('Informe os 5 primeiros dígitos do CPF do gerente');
      return;
    }

    if (step === 0) {
      const gerente = await retornarGerente(cpfGerente);
      if (!gerente) {
        setLoading(false);
        message.error('CPF do gerente inválido');
        return;
      }

      setGerente(`${gerente.nome} ${gerente.sobrenome}`);
    }

    setStep(step + 1);
    setLabelNextButton('Confirmar');
    setLoading(false);

    //await onSubmit(checkedList);
  };

  const handleCancel = () => {
    onCancel();
    setCheckedList([]);
    setLoading(false);
  };

  const handleCpfChange = e => {
    setCpfGerente(e.target.value);
  };

  const btnChangeOrderClick = (vendedor, direction) => {
    const idxVendedor = dataFila.findIndex(item => item.id === vendedor.id);

    if (idxVendedor === -1) {
      message.error('Vendedor não encontrado na fila');
      return;
    }

    const limit = dataFila.length - 1;

    if (vendedor.status.order <= 0 && direction === Direction.UP) {
      message.error(
        'Não é possível subir a ordem do primeiro vendedor da fila',
      );
      return;
    }

    if (vendedor.status.order >= limit && direction === Direction.DOWN) {
      message.error('Não é possível descer a ordem do último vendedor da fila');
      return;
    }

    setLoading(true);
    self.setState({ loading: true });

    let swapIndex;
    if (direction === Direction.UP) {
      swapIndex = idxVendedor - 1;
    } else {
      swapIndex = idxVendedor + 1;
    }

    // Troca a ordem apenas no array de vendedores
    const tempOrder = vendedor.status.order;
    vendedor.status.order = dataFila[swapIndex].status.order;
    dataFila[swapIndex].status.order = tempOrder;

    // Atualiza todos os 'order' dos vendedores para evitar conflitos
    dataFila.sort((a, b) => a.status.order - b.status.order);
    dataFila.forEach((vend, index) => {
      vend.status.order = index;
      vend.status.descricao = `${index + 1}º da fila`;

      const vendedorRef = fbDatabase.collection("vendedores").doc(vend.id);
      const status = vend.status;
      vendedorRef.update({ status});
    });

    setTimeout(async () => {

      const gestorId = await getGestorId();

      const mensagemAuditoria = `${vendedor.nome} teve sua ordem de atendimento alterada para ${vendedor.status.order + 1}º da fila pelo gerente ${gerente}`;
      const auditoria = {
        mensagem: mensagemAuditoria,
        tipo: 'alteracao manual da ordem de atendimento',
        data: new Date(),
        gestorId
      };

      fbDatabase.collection('auditoria').add(auditoria);

      setLoading(false);
      self.setState({ loading: false });        
    }, 1500);
  };

  return (
    <div>
      <Modal
        maskTransitionName="slide-down"
        bodyStyle={{ height: 300 }}
        open={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        title="Mudar ordem de vendedores na Fila de Atendimento"
        destroyOnClose
        wrapClassName="rounded-modal"
        maskClosable={false}
        closable={!loading}
        footer={[
          <div>
            <Button key="back" onClick={handleCancel} disabled={loading}>
              Cancelar
            </Button>
            <Button key="submit" type="primary" onClick={handleOk}>
              {labelNextButton}
            </Button>
          </div>,
        ]}
      >
        {step === 0 && (
          <div className="divSenhaGerent">
            <br></br>
            <label>Informe os primeiros 6 dígitos do CPF do gerente:</label>

            <Input
              value={cpfGerente}
              onChange={handleCpfChange}
              placeholder="CPF do gerente"
              maxLength={6}
              style={{ width: '200px', margin: '0 auto', display: 'block' }}
            />
          </div>
        )}

        {step === 1 && (
          <div className="lista-vendedores-content-configuracoes">
            <List
              className="lista-vendedores-configuracoes"
              loading={loading}
              locale={{
                emptyText: (
                  <Empty
                    imageStyle={{
                      height: 48,
                    }}
                    className="adicionar-vendedores-empty-container"
                    description={
                      <div>
                        <strong className="adicionar-vendedores-empty-container-strong">
                          Não há vendedores disponíveis para atendimento
                        </strong>
                        <br></br>
                        <span className="adicionar-vendedores-empty-container-description">
                          Você só pode ordenar vendedores que estejam
                          disponíveis para atendimento
                        </span>
                      </div>
                    }
                  ></Empty>
                ),
              }}
              dataSource={dataFila}
              renderItem={(item, index) => (
                <List.Item
                  key={item.id}
                  style={{
                    border: 'ridge 1px',
                    backgroundColor: item.backgroundColor,
                  }}
                >
                  <List.Item.Meta
                    avatar={<Avatar size={52} src={item.avatar} />}
                    className="list-item-meta-avatar-configuracoes"
                    title={formatarNome(
                      item.apelido && item.apelido !== ''
                        ? item.apelido
                        : item.nome,
                    )}
                    description={
                      <div>
                        <p>{item.status.descricao}</p>
                        <Badge
                          className="sellerBadge"
                          count={<IconFont type={item.status.icon} size={20} />}
                        >
                        </Badge>
                      </div>
                    }
                  />
                  {!item.status.emAtendimento && (
                    <div className="div-mudar-ordem-vendedores-fila">
                      <Button
                        className="btn-mudar-ordem"
                        icon={<ArrowUpOutlined />}
                        disabled={loading}
                        onClick={() => btnChangeOrderClick(item, Direction.UP)}
                      ></Button>

                      <Button
                        className="btn-mudar-ordem"
                        icon={<ArrowDownOutlined />}
                        disabled={loading}
                        onClick={() =>
                          btnChangeOrderClick(item, Direction.DOWN)
                        }
                      ></Button>
                    </div>
                  )}
                  {item.status.emAtendimento && (
                    <div className="sellerButtons"></div>
                  )}
                </List.Item>
              )}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ConfiguracoesFila;
