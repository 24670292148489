import React, { useState } from 'react';
import { Select, InputNumber, Descriptions, Modal, Switch, Input as Inputstr, Button, Space, message } from 'antd';
import { fbDatabase } from '../../firebase/firebase';
import Input from "../intl/IntlCurrencyInput";
import { GiftOutlined } from '@ant-design/icons';
import { avisar } from '../../pages/fila/Functions-Fila';



import './Styles.css';

const { Option } = Select;

function BuscarProdutos(props) {
    const PLACEHOLDER_FORA_MIX = 'Informe o que não tem no Mix';

    const [foraMix, setForaMix] = useState(props.foraMix);
    const [buscaForaMix, setBuscaForaMix] = useState(props.buscaForaMix);
    const [produtoForaMix, setProdutoForaMix] = useState('');
    const [buscaParcial, setBuscaParcial] = useState(true);
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [loading, setLoading] = useState(false);
    const [abrirModalValor, setAbrirModalValor] = useState(false);
    const [produtoSelecionado, setProdutoSelecionado] = useState('');
    const [optionProdutoSelecionado, setOptionProdutoSelecionado] = useState(undefined);
    const [valorUnitario, setValorUnitario] = useState(0);
    const [quantidade, setQuantidade] = useState(1);

    const [productOptions, setProductOptions] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');

    const [outOfMixOptions, setOutOfMixOptions] = useState([]);
    const [selectedOutOfMix, setSelectedOutOfMix] = useState('');


    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                "pt-BR": {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }
            },
        },
    };

    async function localizarForaMix(gestorId, termoBusca) {
        setLoading(true);
        termoBusca = termoBusca.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();

        let snapshot = fbDatabase
            .collection('fora-mix')
            .where('gestorId', '==', gestorId)
            .where('ativo', '==', true)

        if (buscaParcial) {
            snapshot = snapshot.orderBy('busca')
                .startAt(termoBusca)
                .endAt(termoBusca + '\uf8ff');
        } else {
            const words = termoBusca.split(' ');
            for (let i of words) {
                snapshot = snapshot.where("keywords." + i, "==", "true");
            }
        }

        snapshot = await snapshot.limit(100).get();

        const foraMix = [];
        snapshot.forEach(doc => {
            foraMix.push({
                key: doc.id,
                nome: doc.data().nome,
            });
        });

        const data = [];
        foraMix.forEach(item => {
            data.push(
                <Option key={item.key} value={item.nome}>
                    {item.nome}
                </Option>
            );
        });

        setOutOfMixOptions(data);
        setLoading(false);
    }

    async function localizarProdutos(gestorId, termoBusca) {
        setLoading(true);

        termoBusca = termoBusca.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();

        let snapshot = fbDatabase
            .collection('produtos')
            .where('gestorId', '==', gestorId)
            .where('ativo', '==', true);

        if (buscaParcial) {
            snapshot = snapshot.orderBy('busca')
                .startAt(termoBusca)
                .endAt(termoBusca + '\uf8ff');
        } else {
            const words = termoBusca.split(' ');
            for (let i of words) {
                snapshot = snapshot.where("keywords." + i, "==", "true");
            }
        }

        snapshot = await snapshot.limit(100).get();

        if (snapshot.empty && buscaParcial) {

            snapshot = await fbDatabase
                .collection('produtos')
                .where('gestorId', '==', gestorId)
                .where('ativo', '==', true)
                .where('busca', '==', termoBusca)
                .limit(100)
                .get();
        }

        const snapshotCodigo = await fbDatabase
            .collection('produtos')
            .where('gestorId', '==', gestorId)
            .where('ativo', '==', true)
            .orderBy('codigo')
            .where('codigo', '>=', termoBusca.toUpperCase())
            .where('codigo', '<=', termoBusca.toUpperCase() + '\uf8ff')
            .limit(100)
            .get();

        const produtos = [];

        snapshot.forEach(doc => {
            produtos.push({
                key: doc.id,
                nome: doc.data().codigo ? `${doc.data().nome} (${doc.data().codigo})` : doc.data().nome,
                codigo: doc.data().codigo ? doc.data().codigo : ''
            });
        });

        snapshotCodigo.forEach(doc => {
            produtos.push({
                key: doc.id,
                nome: doc.data().codigo ? `${doc.data().nome} (${doc.data().codigo})` : doc.data().nome,
                codigo: doc.data().codigo ? doc.data().codigo : ''
            });
        });

        const data = [];
        produtos.forEach(item => {
            data.push(
                <Option key={item.key} value={item.nome} codigo={item.codigo}>
                    {item.nome}
                </Option>
            );
        });

        setProductOptions(data);
        setLoading(false);
    }

    function handleSelectOutOfMix(value, option) {
        setSelectedProduct('');
        setOutOfMixOptions([]);
        props.onSelect({
            key: "--",
            produto: value,
            qtd: 1,
            valorUnitario: 0,
            codigo: option.codigo ? option.codigo : ''
        });
    }

    function handleSelectProduct(value, option) {

        value = option.codigo ?
            value.replace(`(${option.codigo})`, '').trim() : value;

        setSelectedProduct(value);
        setProdutoSelecionado(value);
        setOptionProdutoSelecionado(option);

        if (props.tipoOperacao === 'nao-vendeu') {
            props.onSelect({
                key: option.key,
                produto: value,
                qtd: 1,
                valorUnitario: 0,
                codigo: option.codigo ? option.codigo : ''
            });
            setProductOptions([]);
            setSelectedProduct('');
            return;
        }

        setAbrirModalValor(true);
    }

    function handleProductSearch(value) {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        if (value.length < 3) {
            setProductOptions([]);
            return;
        }

        setSearchTimeout(
            setTimeout(() => {
                localizarProdutos(props.gestorId, value);
            }, 500)
        );
    }

    function handleOutOfMixSearch(value) {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        if (value.length < 3) {
            setProductOptions([]);
            return;
        }

        setSearchTimeout(
            setTimeout(() => {
                localizarForaMix(props.gestorId, value);
            }, 500)
        );
    }



    function handleModalValorCancel() {
        setAbrirModalValor(false);
        setProductOptions([]);
        setSelectedProduct('');
    }

    function handleModalValorOk() {

        if (valorUnitario <= 0 || valorUnitario === 'R$ 0,00') {
            avisar('Valor unitário inválido.');
            return;
        }

        const nomeProduto = optionProdutoSelecionado.codigo ?
            produtoSelecionado.replace(`(${optionProdutoSelecionado.codigo})`, '').trim() : produtoSelecionado;

        props.onSelect({
            key: optionProdutoSelecionado.key,
            produto: nomeProduto,
            qtd: quantidade,
            valorUnitario: parseFloat(valorUnitario.replace(/[^\d.,-]/g, '').replace(',', '.')),
            codigo: optionProdutoSelecionado.codigo ? optionProdutoSelecionado.codigo : ''
        });

        setAbrirModalValor(false);
        setProductOptions([]);
        setSelectedProduct('');
        setQuantidade(1);
    }

    const handleValueChange = (event, value) => {
        event.preventDefault();
        setValorUnitario(value.toFixed(2));
    };

    const handleBuscaForaMixChange = (value) => {
        setBuscaForaMix(!value);
    };


    const handleBuscaParcialChange = (value) => {
        setBuscaParcial(value);
    };


    const handleQuantityChange = (value) => {
        setQuantidade(value);
    };

    const botaoForaMixClick = () => {
        if (produtoForaMix === '' || produtoForaMix.length < 3) {
            message.warn('Informe corretamente o nome do produto.');
            return;
        }

        props.onSelect({
            key: '--',
            produto: produtoForaMix,
            qtd: 1,
            valorUnitario: 0,
        });
        setProductOptions([]);
        setSelectedProduct('');
        setProdutoForaMix('');
    }

    const editForaMixChange = (obj) => {
        setProdutoForaMix(obj.target.value);
    }


    return (
        <div>

            <Modal
                title="Informações Adicionais"
                destroyOnClose={true}
                open={abrirModalValor}
                maskClosable={false}
                closable={true}
                onOk={handleModalValorOk}
                onCancel={handleModalValorCancel}
            >

                <p className='buscar-produto-produto-selecionado'><GiftOutlined className='buscar-produto-produto-selecionado-img' /> {produtoSelecionado}</p>
                <Descriptions className='buscar-produto-label' title="Quantas peças?"></Descriptions>
                <InputNumber
                    inputMode='numeric'
                    min={1}
                    className='buscar-produto-input-number'
                    onChange={handleQuantityChange}
                    value={quantidade}
                />
                <Descriptions className='buscar-produto-label' title="Valor Total (Unitário)"></Descriptions>
                <Input
                    className="buscar-produto-input-number"
                    currency={currencyConfig.locale}
                    config={currencyConfig}
                    value={valorUnitario}
                    onChange={handleValueChange}
                />


            </Modal>

            {!foraMix ? (
                <div>
                    <Switch
                        className='buscar-produtos-por-nome-ou-codigo-switch'
                        checkedChildren="Busca Iniciando Com"
                        unCheckedChildren="Busca Parcial (requer digitar palavras do meio inteiras)"
                        defaultChecked={buscaParcial}
                        onChange={handleBuscaParcialChange}
                    />
                    <br></br>
                    <Select
                        virtual={true}
                        className='buscar-produtos-por-nome-ou-codigo ant-select-centered'
                        showSearch
                        placeholder="Digite o Nome ou Código do produto"
                        optionFilterProp="children"
                        loading={loading}
                        onSelect={handleSelectProduct}
                        value={selectedProduct}
                        filterOption={(input, option) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                        onSearch={handleProductSearch}
                    >
                        {productOptions}
                    </Select>
                    <label className='select-buscar-produtos-por-nome-ou-codigo'>(Digite palavras completas ou o trecho do código | Máximo 100 produtos)</label>
                </div>

            ) : (null)}


            {foraMix ? (
                <div>
                    {props.buscaForaMix ? (
                        <div>

                            <Switch
                                className='buscar-produtos-por-nome-ou-codigo-switch'
                                checkedChildren="Busca Iniciando Com"
                                unCheckedChildren="Busca Parcial (requer digitar palavras do meio inteiras)"
                                defaultChecked={buscaParcial}
                                onChange={handleBuscaParcialChange}
                            />

                            <br></br>

                            <Select
                                virtual={true}
                                className='buscar-fora-mix'
                                showSearch
                                placeholder={PLACEHOLDER_FORA_MIX}
                                optionFilterProp="children"
                                loading={loading}
                                onSelect={handleSelectOutOfMix}
                                value={selectedOutOfMix}
                                filterOption={(input, option) => {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                                onSearch={handleOutOfMixSearch}
                            >
                                {outOfMixOptions}
                            </Select>
                        </div>
                    ) : (
                        <div>
                            <Inputstr type='text' onChange={editForaMixChange} value={produtoForaMix} className='buscar-produto-fora-mix-edit' placeholder={PLACEHOLDER_FORA_MIX} ></Inputstr>
                            <Button type='primary' onClick={botaoForaMixClick} >Adicionar</Button>
                        </div>
                    )}
                </div>
            ) : (null)}

        </div>
    );
}

export default BuscarProdutos;
