import React, { useEffect, useState, useRef } from 'react';
import { Carousel, Button, Tag } from "antd";
import "./FilaFooter.css";
import DraggableCard from './Footer/DraggableCard';
import { entrarNaFila } from '../../pages/fila/Functions-Fila';

const FilaFooter = (props) => {

    const [slidesToShow, setSlidesToShow] = useState(6);
    const isMounted = useRef(true);

    function handleResize() {
        if (window.innerWidth <= 400) {
            setSlidesToShow(2);
        } else if (window.innerWidth <= 605) {
            setSlidesToShow(3);
        } else if (window.innerWidth <= 768) {
            setSlidesToShow(4);
        } else {
            setSlidesToShow(6);
        }
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            isMounted.current = false;  // Marca como desmontado
        };
    }, []);

    const carouselRef = useRef();

    const handlePrev = () => {
        carouselRef.current.prev();
    };

    const handleNext = () => {
        carouselRef.current.next();
    };

    const btnEntrarNaFila = async (vendedor) => {
        // Verifica se o componente está montado antes de continuar
        if (isMounted.current) {
            await entrarNaFila(
                props.self, 
                vendedor, 
                props.self.state.dataFila, 
                props.self.state.dataAusente
            );
        }
    };

    const ausentesSemDuplicatas = props.ausentes;

    return (
        <footer className="fila-footer">
            <div className="fila-footer-container">
                <div className='fila-footer-botoes'>
                    <strong>{`${ausentesSemDuplicatas.length > 1 ? 'Vendedores Ausentes' : 'Vendedor Ausente'} `} <Tag color="#f50">{ausentesSemDuplicatas.length}</Tag></strong>
                    <Button onClick={handlePrev}>{'<'}</Button>
                    <Button onClick={handleNext}>{'>'}</Button>
                </div>
                <Carousel
                    ref={carouselRef}
                    dots={false}
                    slidesToShow={slidesToShow}
                    slidesToScroll={1}
                    autoplay={false}
                    draggable={true}
                    infinite={false}
                >
                    {ausentesSemDuplicatas.map((vendedor, index) => (
                        <DraggableCard
                            key={vendedor.id}
                            index={index}
                            id={vendedor.id}
                            text={vendedor.apelido}
                            vendedor={vendedor}
                            btnEntrarNaFilaCallBack={btnEntrarNaFila}
                        />
                    ))}
                </Carousel>
            </div>
        </footer>
    );
};

export default FilaFooter;
