import { firebaseImpl as fireBase } from '../../firebase/firebase';
import ls from 'local-storage'


export function authenticate(loja) {
    ls.set('loja-fila', loja);
}

export async function logout() {
    await fireBase.auth().signOut().then(function () {
        ls.set('loja-fila', null);
    }, function (error) {
        console.log(error)
        // An error happened.
    });
}

export async function currentUser() {
    return new Promise((resolve) => {
        fireBase.auth().onAuthStateChanged(function (user) {
            if (user) {
                resolve(ls.get('loja-fila'));
            } else {
                return resolve(undefined);
            }
        });
    });
}

export async function isLoggedIn() {
    const loja = await currentUser();
    return loja !== null && loja !== undefined;
}

export async function getEmpresaId() {
    const loja = await currentUser();
    return loja ? loja.id : undefined;
}

export async function getGestorId() {
    const user = await currentUser();
    return user ? user.gestorId : undefined;
}