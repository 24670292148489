import { Modal } from "antd";
import { fbDatabase, fbFirestore } from "../../firebase/firebase";
import {
  authenticate,
  currentUser,
  getEmpresaId,
  getGestorId,
} from "../../components/auth/auth-provider";
import history from "../../history";
import { logout } from "../../components/auth/auth-provider";
import ls from 'local-storage'
import { subMonths, startOfMonth, endOfMonth } from "date-fns";
import filaStorage from "../../components/localstorage/localstorage";
import CryptoJS from 'crypto-js';
import pluralize from 'pluralize';


const { confirm } = Modal;
const { warning } = Modal;

const AUSENTE = 0;
const NA_FILA = 1;

const REDUZIR_FILA = -1;
const AUMENTAR_FILA = 1;

async function confirmar(message) {
  return new Promise((resolve, reject) => {
    confirm({
      title: "Confirmação",
      content: message,
      okText: "Sim",
      cancelText: "Não",
      async onOk() {
        resolve(true);
      },
      onCancel() {
        reject(false);
      },
    });
  }).catch((error) => {
    return false;
  });
}

export function formatarMoeda(valor) {
  valor = parseFloat(valor).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
  return valor;
}

export async function avisar(message) {
  return new Promise((resolve, reject) => {
    warning({
      title: "Atenção",
      content: message,
      async onOk() {
        resolve(true);
      },
    });
  }).catch((error) => {
    console.log("Oops errors!");
    return false;
  });
}

function getBackGroundColor(emAtendimento, furoufila) {
  if (emAtendimento && !furoufila) {
    return "#d5e3f3";
  } else if (emAtendimento && furoufila) {
    return "#FFEBD7";
  } else {
    return "white";
  }
}

function getProximaPosicao(fila) {
  const listaStatusAtendimento = fila.map((item) => item.status.emAtendimento);
  const proximaPosicao = listaStatusAtendimento.lastIndexOf(false) + 1;

  return proximaPosicao;

  // A solução abaixo também funciona, deixo aqui
  // para posterior comparação de performance como estudo

  // for (let i = fila.length - 1; i >= 0; i--) {
  //   if (!fila[i].status.emAtendimento) {
  //     return i + 1;
  //   }
  // }
  //
  // return 0;
}

function setLoading(self, loading) {
  if (self) {
    if (loading) {
      self.setState({ loading });
    } else {
      setTimeout(() => {
        self.setState({ loading });
      }, 1000);
    }
  }
}


function removerVendedor_v2(listaVendedores, vendedorId) {
  const index = listaVendedores.findIndex((item) => item.id === vendedorId);
  return index !== -1 ? listaVendedores.splice(index, 1)[0] : null;
}


function removerVendedor(listaVendedores, vendedorId, self) {

  setLoading(self, true);

  return new Promise(async (resolve) => {
    const index = listaVendedores.findIndex((item) => item.id === vendedorId);

    if (index !== -1) {
      const vendedor = listaVendedores.splice(index, 1)[0];
      setLoading(self, false);
      resolve(vendedor);
      return;
    }
    setLoading(self, false);
    resolve(null);
  });
}

function removerVendedorAusente(vendedorId, self) {
  const dataAusente = self.state.dataAusente.filter((item) => {
    return item.id !== vendedorId;
  });
  self.setState({ dataAusente });
}

function converterVendedorDoc(id, dados) {
  const vendedor = {
    id: id,
    ativo: dados.ativo,
    apelido: dados.apelido,
    nome: dados.nome,
    cpf: dados.cpf,
    avatar: dados.avatar,
    gestorId: dados.gestorId,
    status: {
      data: dados.status.data ? dados.status.data.toDate() : "",
      descricao: dados.status.descricao,
      icon: dados.status.icon,
      order: dados.status.order,
      posicao: dados.status.posicao,
      posicaoOriginal: dados.status.posicaoOriginal,
      emAtendimento: dados.status.emAtendimento,
      furouFila: dados.status.furouFila,
      qtdAtendimentos: dados.status.qtdAtendimentos,
      ultimaSaidaId: dados.status.ultimaSaidaId ? dados.status.ultimaSaidaId : ""
    },
    empresa: {
      ...dados.empresa,
    },
    backgroundColor: getBackGroundColor(
      dados.status.emAtendimento,
      dados.status.furouFila
    ),
  };

  return vendedor;
}

export function desconectar() {
  logout();
  ls.set('loja-fila', null);
  filaStorage.clearAll();
  history.push("/login");
  history.go();
}

export async function listenVendChange() {
  const empresaId = await getEmpresaId();
  const usuario = await currentUser();

  if (usuario.setores && usuario.setores.length > 0 && !this.state.setorSelecionado) {
    return;
  }

  console.log("Debugging -- collection vendedores 1 -- listenVendChange");
  let query = fbDatabase
    .collection("vendedores")
    .where("empresa.id", "==", empresaId)

  if (this.state.setorSelecionado) {
    query = query.where("empresa.setor", '==', this.state.setorSelecionado);
  }

  query
    .onSnapshot((querySnapshot) => {
      const changes = querySnapshot.docChanges();
      const { dataAusente: ausentes } = this.state;
      const self = this;

      console.clear();
      console.log(`changes length: ${changes.length}`);
      changes.forEach((change) => {
        console.log(`has pending writes: ${change.doc.metadata.hasPendingWrites}`);
        console.log(`type: ${change.type}`);
        console.log(`Vendedor: ${change.doc.data().apelido} - ${change.doc.data().status.descricao}`);

        const modificadoNoServidor = !change.doc.metadata.hasPendingWrites;
        const docFoiModificado = change.type === "modified" || change.type === "removed";
        const docFoiAdicionado = change.type === "added";
        if (modificadoNoServidor && (docFoiModificado || docFoiAdicionado)) {

          let vendedor = ausentes.find((item) => item.id === change.doc.id);
          const dadosDocModificado = change.doc.data();

          if (
            vendedor &&
            //vendedor.empresa.id !== dadosDocModificado.empresa.id
            change.type === "removed"
          ) {
            // Trocou de empresa
            removerVendedorAusente(vendedor.id, self);
          } else {
            const timeout = 1000;

            // não remova o settimeout, em casos de added,
            // não carrega a imagem se não o tiver
            setTimeout(
              vendedorSnapshotCallback,
              timeout,
              self,
              change,
              vendedor,
              dadosDocModificado
            );
          }
        }
      });
    });
}

async function vendedorSnapshotCallback(self, change, vendedor, dadosDocModificado) {

  const { dataFila: fila, dataAusente: ausentes } = self.state;

  // Vendedor pode estar na lista de ausentes, na fila ou ser novo
  vendedor =
    vendedor ||
    ausentes.find((item) => item.id === change.doc.id) ||
    fila.find((item) => item.id === change.doc.id);

  const isVendedorNovo = vendedor === undefined;
  const posicaoAntiga = !isVendedorNovo ? vendedor.status.posicao : null;
  const posicaoAtual = dadosDocModificado.status.posicao;
  const atendimentoFoiAlterado = !isVendedorNovo
    ? (
      vendedor.status.emAtendimento !== dadosDocModificado.status.emAtendimento) ||
    vendedor.apelido !== dadosDocModificado.apelido ||
    vendedor.status.descricao !== dadosDocModificado.status.descricao
    : false;
  const ordemFilaAntiga = !isVendedorNovo ? vendedor.status.order : null;
  const qtdAtendimentosAntiga = !isVendedorNovo ? vendedor.status.qtdAtendimentos : null;
  const qtdAtendimentosAtual = dadosDocModificado.status.qtdAtendimentos;

  vendedor = converterVendedorDoc(change.doc.id, dadosDocModificado);

  if (isVendedorNovo && vendedor.ativo) {
    // Sistema carregando ou então o vendedor foi adicionado após a abertura da fila
    if (vendedor.status.posicao === 1) {
      fila.push(vendedor);
      // self.setState({ dataFila: fila });
      ajustarOrdemCarregamento(fila, self);
    } else {
      ausentes.push(vendedor);
      self.setState({ dataAusente: ausentes });
    }
  } else if (!isVendedorNovo) {
    const trocouPosicao = posicaoAntiga !== posicaoAtual;
    const alterouQtdAtendimentos = qtdAtendimentosAntiga !== qtdAtendimentosAtual;
    if (trocouPosicao && posicaoAntiga === AUSENTE && vendedor.ativo) {
      await removerVendedor(ausentes, vendedor.id, self);
      console.log("Debugging -- inserirNaFilaDeEspera 1");
      inserirNaFilaDeEspera(vendedor, fila);
    } else if (!trocouPosicao && posicaoAntiga === AUSENTE && !vendedor.ativo) {
      await removerVendedor(ausentes, vendedor.id, self);
    } else if (trocouPosicao && posicaoAntiga === NA_FILA) {
      const vendedorRemovido = await removerVendedor(fila, vendedor.id, self);
      if (vendedorRemovido) {
        reposicionarOrdemNaFila(fila, vendedorRemovido.status.order);
        ausentes.push(vendedor);
      }
    } else if (atendimentoFoiAlterado) {
      await removerVendedor(fila, vendedor.id, self);

      if (vendedor.status.emAtendimento) {
        configurarVendedorAtendimento(fila, ordemFilaAntiga, vendedor);
      } else {
        reposicionarOrdemNaFila(fila, ordemFilaAntiga, REDUZIR_FILA);
        console.log("Debugging -- inserirNaFilaDeEspera 2");
        //inserirNaFilaDeEspera(vendedor, fila);
      }
      // self.setState({ dataFila: fila });
    } else if (alterouQtdAtendimentos) {
      const index = fila.findIndex(item => item.id === vendedor.id);
      // const index = fila.findIndex((el, index, arr) => {
      //   if (el.id !== vendedor.id) {
      //     return false;
      //   }
      //   return index;
      // });
      fila.splice(index, 1, vendedor);
    } else {
      // setTimeout(() => {
      //   ajustarLabelsFila(self);
      // }, 500);
    }


    /*else if(saiu){
      const index = ausentes.findIndex((el, index, arr) => {
        if(el.id !== vendedor.id){
          return false;
        }
        return index;
      });
      ausentes.splice(index, 1, vendedor);
    }*/

    verificarVendedorDuplicado(fila);
    const filaOrdenada = ajustarLabelsFila(fila);
    self.setState({ dataFila: filaOrdenada });
    self.setState({ dataAusente: ausentes });
  }
}

async function listenEmpChange(empresaId) {

  if (!empresaId) {
    return;
  }

  fbDatabase
    .collection("empresas")
    .doc(empresaId)
    .onSnapshot(async (doc) => {
      const usuario = await currentUser();
      const dados = doc.data();
      if (!dados || (dados.gestorDesativado === true || dados.ativo === false)) {
        desconectar();
        return;
      }
      dados.id = doc.id;
      const passwordChanged = !dados.login.ultimaAlteracaoSenha ? false : usuario.ultimoLogin / 1000 < dados.login.ultimaAlteracaoSenha.seconds;

      if (dados.login.login !== usuario.login.login || passwordChanged) {
        if (passwordChanged) {
          fbDatabase.collection('empresas')
            .doc(dados.id)
            .update({
              "login.ultimaAlteracaoSenha": null
            })
        }

        warning({
          title: "Aviso",
          content:
            "Essa seção será encerrada, pois o gestor alterou os dados de login.",
          async onOk() {
            desconectar();
          },
        });
      } else {
        dados.ultimoLogin = usuario.ultimoLogin;
        delete dados.login.password;
        const loja = {
          id: doc.id,
          ...dados,
        };

        authenticate(loja);
      }
    });
}

function verificarVendedorDuplicado(fila) {
  // eslint-disable-next-line
  fila.map((item) => {
    let indexes = [];
    // eslint-disable-next-line
    fila.filter((vendedor, index) => {
      if (vendedor.id === item.id) {
        indexes.push(index);
      }
    });
    if (indexes.length > 1) {
      fila.splice(indexes[1], 1)
    }
  })
}

function ajustarOrdemCarregamento(fila, self) {
  const emAtendimento = fila.filter((vendedor) => vendedor.status.emAtendimento);
  const naFila = fila.filter((vendedor) => !vendedor.status.emAtendimento);

  emAtendimento.sort((a, b) => (a.status.order > b.status.order) ? 1 : -1);
  naFila.sort((a, b) => (a.status.order > b.status.order) ? 1 : -1);

  const novaFila = naFila.concat(emAtendimento);

  verificarVendedorDuplicado(novaFila);

  //fila.sort((a, b) => (a.status.order > b.status.order) ? 1 : -1);
  self.setState({ dataFila: novaFila }); //fila });
}

function ajustarLabelsFila(fila) {

  let posicao = 0;
  fila.forEach((item) => {
    const status = item.status;
    if (!item.status.emAtendimento) {
      status.order = posicao;
      status.posicaoOriginal = -1;
      status.descricao = `${posicao + 1}º da fila`;
      posicao++;
    }
  });
  return fila;
}

function retornarVendedorQueEstavaEmAtendimentoParaPrimeiraPosicaoNaFila(vendedor, fila, self) {

  let posicao = 1;

  fila.forEach((item) => {

    const status = item.status;

    if (vendedor.id === item.id && item.status.emAtendimento) {
      const novaPosicao = status.posicaoOriginal !== undefined ? status.posicaoOriginal : status.order;

      status.order = novaPosicao;
      status.descricao = `${novaPosicao + 1}º da fila`;

      status.posicaoOriginal = -1;
      status.emAtendimento = false;
      vendedor.backgroundColor = getBackGroundColor(
        false,
        false
      );

    } else if (!item.status.emAtendimento) {
      const novaPosicao = item.status.order < vendedor.status.posicaoOriginal ? item.status.order : posicao;
      status.order = novaPosicao;
      status.posicaoOriginal = -1;
      status.descricao = `${novaPosicao + 1}º da fila`
    }
    posicao++;
  });

  atualizarColecaoVendedores(self, fila);

  const compararPorOrder = (a, b) => {
    if (a.status.order < b.status.order) {
      return -1;
    } else if (a.status.order > b.status.order) {
      return 1;
    }
    return 0;
  };

  fila.sort(compararPorOrder);
}

export function reposicionarOrdemNaFila(fila, inicioAjuste, variacao = REDUZIR_FILA) {
  // Ajusta a ordem de todos os vendedores da lista
  // que estão depois da posição informada

  // proteção nova!
  if (inicioAjuste < 0) {
    //inicioAjuste = 0;
    inicioAjuste = getProximaPosicao(fila);
  }

  fila.forEach((item) => {
    const status = item.status;
    if (status.order >= inicioAjuste) {
      status.order = status.order < 0 ? 0 : status.order;
      status.order = status.order + variacao;

      // Ok! This is weird
      // if (!status.emAtendimento) {
      //   modificarLegendaFila(item);
      // }
    }
  });
}

export async function editAtendimento(args, AddAtendimento = 1) {
  const vendedor = args.user;
  const fila = args.self.state.dataFila;
  const qtdAtendimentos = vendedor.status.qtdAtendimentos === undefined ? 1 : vendedor.status.qtdAtendimentos;
  vendedor.status.qtdAtendimentos = qtdAtendimentos + AddAtendimento;
  const descricao = vendedor.status.furouFila ?
    'Atendimento fora da vez (' + vendedor.status.qtdAtendimentos + ')'
    : 'Em atendimento (' + vendedor.status.qtdAtendimentos + ')';

  modificarLegendaFila(vendedor, descricao);

  if (AddAtendimento === -1) {
    fila.splice(vendedor.status.order, 0, vendedor)
  }

  args.self.atualizarColecaoVendedores(args.self, args.self.state.dataFila);
}

export async function loadConfig() {
  const gestorId = await getGestorId();

  try {
    fbDatabase
      .collection("configuracao")
      .where("gestorId", "==", gestorId)

      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const doc = snapshot.docs[0];
          const result = { id: doc.id, ...doc.data() };

          if (doc.data().fila !== undefined) {
            const config = {
              registroProdutos: doc.data().fila.atendimento.registroProdutos,
              vendaTemporaria: doc.data().fila.atendimento.vendaTemporaria,
              exibirDesempenhoVendas: doc.data().fila.atendimento.exibirDesempenhoVendas,
              exigirSenhaDesempenhoVendas: doc.data().fila.atendimento.exigirSenhaDesempenhoVendas,
              exibirIndicador: doc.data().fila.atendimento.exibirIndicador !== undefined ? doc.data().fila.atendimento.exibirIndicador : false,
            };
            this.setState({ config });
          }
          return result;
        }
        return null;
      });


      fbDatabase
      .collection("fora-mix")
      .where("gestorId", "==", gestorId)
      .where("ativo", "==", true)
      .onSnapshot((snapshot) => {
        const config = this.state.config;
        config.foraMixCadastro = snapshot.empty ? false : true;
        this.setState({ config });
      });



  } catch (error) {
    console.log(error);
    return undefined;
  }
}

/*async function registrarAbertura(empresaId) {
  fbDatabase
    .collection("x-caregamento-fila")
    .add({
      empresaId,
      data: new Date(),
    });
}*/

export async function carregarVendedores() {
  console.log("Debugging -- carregarVendedores");
  const empresaId = await getEmpresaId();
  if (empresaId) {
    await listenEmpChange(empresaId);
  }
  return {
    ausente: [],
    fila: [],
  };
}

async function getConfig() {
  const gestorId = await getGestorId();

  const snapshot = await fbDatabase.collection('configuracao')
    .where("gestorId", "==", gestorId)
    .get();

  const config = snapshot.empty ? undefined : snapshot.docs[0].data();

  const bloqueia = config !== undefined ? config.bloquearVendedorForaAtendimento : true;

  return bloqueia;
}

function inserirNaFilaDeEspera(vendedor, fila, data = undefined) {
  // console.log("Debugging -- inserirNaFilaDeEspera");
  const posicaoNaFila = getProximaPosicao(fila);

  reposicionarOrdemNaFila(fila, posicaoNaFila, AUMENTAR_FILA);
  reconfigurarStatusVendedor(vendedor, {
    posicao: NA_FILA,
    order: posicaoNaFila,
    data: data,
  });

  fila.splice(vendedor.status.order, 0, vendedor);
}

export function habilitaSnapshotUpdate(self, habilitado) {
  console.log("Debugging -- habilitaSnapshotUpdate");
  if (!self) {
    return;
  }
  self.setState({ ignoreSnapshotUpdate: !habilitado });
}

export async function adicionarVendedoresNaFilaEmLote(listaVendedoresIds, dataFila, dataAusente) {
  console.log("Debugging -- adicionarVendedoresNaFilaEmLote");
  if (!listaVendedoresIds) {
    return;
  }

  for (let i = 0; i < listaVendedoresIds.length; i++) {
    const user = { id: listaVendedoresIds[i] };
    await entrarNaFila(this, user, dataFila, dataAusente)
  };

}

export async function entrarNaFila(self, user, dataFila, dataAusente) {
  console.log("Debugging -- entrarNaFila");
  const fila = dataFila;
  const ausentes = dataAusente;

  let vendedorAusente = await removerVendedor(ausentes, user.id, self);

  if (this) {
    this.setState({ ignoreTimeoutUpdate: true });
  }

  console.log("Debugging -- inserirNaFilaDeEspera 3");
  inserirNaFilaDeEspera(vendedorAusente, fila);

  habilitaSnapshotUpdate(self, false);

  let ultimaSaida = '';

  console.log("Debugging -- collection vendedores 2 -- entrar na fila");
  await fbDatabase
    .collection("vendedores")
    .doc(vendedorAusente.id)
    .get()
    .then((res) => {
      ultimaSaida = res.data().status.ultimaSaidaId;
    });

  if (!ultimaSaida) {
    ultimaSaida = await criarHistorico(vendedorAusente)
  }

  if (vendedorAusente.status.posicaoOriginal === undefined) {
    delete vendedorAusente.status.posicaoOriginal;
  }

  delete vendedorAusente.status.ultimaSaidaId;
  delete vendedorAusente.status.serverDate;

  console.log("Debugging -- collection vendedores 3 -- entrar na fila");
  fbDatabase
    .collection("vendedores")
    .doc(vendedorAusente.id)
    .update({
      status: vendedorAusente.status,
    })
    .then(() => {
      habilitaSnapshotUpdate(self, true);
    });

  fbDatabase
    .collection("historico")
    .doc(ultimaSaida)
    .update({
      dataRetorno: vendedorAusente.status.data
    }).then(() => {
      habilitaSnapshotUpdate(self, true);
    });
}

export async function iniciarAtendimento(self, user) {
  console.log("Debugging -- iniciarAtendimento");
  if (self.state.aguardarProcessamentoFila) {
    return;
  }

  const loggedUser = await currentUser();
  const bloqueiaFurarFila = await getConfig();

  if (user.status.order !== 0 && (!loggedUser.furarFila || bloqueiaFurarFila)) {
    await avisar(`${user.apelido} não está na frente da fila.`);
    return;
  }

  const pergunta = `${user.apelido} não está na frente da fila. Deseja iniciar o atendimento mesmo assim?`;
  let isOk = user.status.order === 0 ? true : await confirmar(pergunta);

  if (!isOk) {
    return;
  }

  self.setState({ aguardarProcessamentoFila: true });

  const fila = self.state.dataFila;
  const atendente = await removerVendedor(fila, user.id, self);
  const ordemAtendente = user.status.order;

  atendente.status.furouFila = user.status.order !== 0;
  atendente.status.posicaoOriginal = user.status.order;
  configurarVendedorAtendimento(fila, ordemAtendente, atendente);

  self.atualizarColecaoVendedores(self, fila, atendente);
  self.setState({ ignoreTimeoutUpdate: true });

  setTimeout(() => {
    self.setState({
      ignoreTimeoutUpdate: false,
      aguardarProcessamentoFila: false,
    });
  }, 300);
}

function configurarVendedorAtendimento(fila, ordemAtendente, atendente) {
  console.log("Debugging -- configurarVendedorAtendimento");
  reposicionarOrdemNaFila(fila, ordemAtendente);
  fila.push(atendente);

  const atendenteOrder = fila.length - 1;

  // O código abaixo diz que o atendente está atendendo fora da fila
  //  erradamente quando o fila é recarregado
  // const atendimentoForaVez = ordemAtendente > 0;

  const atendimentoForaVez = atendente.status.furouFila;
  reconfigurarStatusVendedor(atendente, {
    posicao: NA_FILA,
    order: atendenteOrder,
    descricao: atendimentoForaVez
      ? "Atendimento fora da vez"
      : "Em atendimento",
    emAtendimento: true,
    furouFila: atendimentoForaVez,
  });

  atendente.backgroundColor = getBackGroundColor(
    atendente.status.emAtendimento,
    atendente.status.furouFila
  );
}

export function atualizarColecaoVendedores(self, fila, vendedor) {

  console.log("Debugging -- atualizarColecaoVendedores");


  fila.forEach((item) => {

    habilitaSnapshotUpdate(self, false);

    item.status.qtdAtendimentos = !item.status.qtdAtendimentos ? 1 : item.status.qtdAtendimentos;

    console.log("Debugging -- collection vendedores 4 -- atualizarcolecaovendedores");
    const vendedorRef = fbDatabase.collection("vendedores").doc(item.id);
    const status = { ...item.status, data: item.status.serverDate || item.status.data };

    delete status.serverDate;
    if (status.posicaoOriginal === undefined && vendedor !== undefined && vendedor.id === item.id) {
      status.posicaoOriginal = vendedor.status.posicaoOriginal ? vendedor.status.posicaoOriginal : 0;
    } else if (status.posicaoOriginal === undefined) {
      status.posicaoOriginal = item.status.order;
    }

    vendedorRef
      .update({
        status: status
      })
      .then(() => {
        habilitaSnapshotUpdate(self, true);
      });

    item.serverDate = null;
  });
}

async function registrarConversao(data) {

  console.log("Debugging -- registrarConversao");

  return new Promise((resolve, reject) => {

    const {
      user,
      produtos,
      produtosTroca,
      troca,
      vendeu,
      pecas: totalPecas,
      valor: valorTotal,
      motivos,
      indicacao,
      vendaTemporaria,
      experimentou,
    } = data;

    let conversao = {
      empresaId: user.empresa.id,
      gestorId: user.gestorId,
      dataInicial: user.inicioAtendimento,
      vendaTemporaria,
      // dataFinal: user.fimAtendimento,
      dataFinal: fbFirestore.FieldValue.serverTimestamp(),
      vendedor: {
        id: user.id,
        nome: user.nome
      },
      estavaEmAtendimento: true,
      vendeu
    };


    if (produtos) {
      conversao = { ...conversao, produtos };
    }

    if (vendeu) {
      conversao = { ...conversao, totalPecas, valorTotal };
    } else if (motivos) {
      conversao = { ...conversao, motivos };
    }

    if (experimentou !== undefined) {
      conversao = { ...conversao, experimentou };
    }

    if (troca === true) {
      conversao = { ...conversao, troca, produtosTroca };
    } else {
      conversao = { ...conversao, troca };
    }


    const conversaoString = JSON.stringify(conversao);
    const conversaoId = CryptoJS.SHA256(conversaoString).toString();
    const conversaoRef = fbDatabase.collection("conversoes").doc(conversaoId);

    conversaoRef
      .set({ ...conversao })
      .then(async () => {
        await registrarIndicacao(indicacao, valorTotal, conversaoRef.id, user.empresa.id);
        resolve(true);

      })
      .catch(function (error) {
        console.error("Erro ao criar não conversão: ", error);
        resolve(false);
      });
  });
}

function compararProdutosJson(json1, json2) {
  if (json1.length !== json2.length) {
    return false;
  }

  for (let i = 0; i < json1.length; i++) {
    if (json1[i].id !== json2[i].id) {
      return false;
    }
  }

  return true;
}

export async function operacaoConversaoProxima(vendedorId, produtos) {
  const timeThreshold = 300; //segundos
  const now = Date.now();
  const minTime = now - timeThreshold * 1000;
  const maxTime = now + timeThreshold * 1000;

  const conversoesRef = await fbDatabase.collection('conversoes')
    .where('vendedor.id', '==', vendedorId)
    .where('dataFinal', '>=', new Date(minTime))
    .where('dataFinal', '<=', new Date(maxTime))
    .get();

  let bloquear = false;
  if (produtos && conversoesRef.docs.length > 0) {

    conversoesRef.docs.forEach(conversao => {
      if (!bloquear) {
        bloquear = compararProdutosJson(produtos, conversao.data().produtos);
      }
    })




  }

  return bloquear;
}

async function registrarIndicacao(indicacao, valor, conversaoId, empresaId) {

  // eslint-disable-next-line no-mixed-operators
  if (!indicacao || (!indicacao.id && !indicacao.cpf && !indicacao.codigo && !indicacao.telefone)) {
    return;
  }

  // ========== Cálculo da comissão

  // Pego quem fez a indicação
  const indicador = await fbDatabase
    .collection("indicadores")
    .doc(indicacao.indicador.id)
    .get();


  // Pego a loja de quem fez a indicação
  const loja = await fbDatabase
    .collection("lojas")
    .doc(indicador.data().loja.id)
    .get();

  let comissao = 0;
  if (loja.data().comissao.percentual > 0) {
    comissao = (valor / 100) * loja.data().comissao.percentual;
    if (comissao < loja.data().comissao.fixo) {
      comissao = loja.data().comissao.fixo;
    }
  } else {
    comissao = loja.data().comissao.fixo;
  }

  fbDatabase
    .collection("indicacoes")
    .doc(indicacao.id)
    .update({
      empresaId,
      valorEmCentavos: valor * 100, // Em centavos
      converteu: true,
      dataCompra: new Date(),
      conversaoId,
      comissaoEmCentavos: comissao * 100,
    });
}

export async function finalizarAtendimento(args) {
  console.log("Debugging -- finalizarAtendimento");

  return new Promise(async (resolve, reject) => {
    if (args.voltarParaPrimeiraPosicao === true) {
      args.self.setState({ loading: true });
      if (args.user.status.qtdAtendimentos > 1) {
        editAtendimento(args, -1)
      } else {
        const fila = args.self.state.dataFila;
        retornarVendedorQueEstavaEmAtendimentoParaPrimeiraPosicaoNaFila(
          args.user,
          fila,
          args.self
        );
      }
      resolve(true);
      args.self.setState({ loading: false });
      return;
    }

    if (await operacaoConversaoProxima(args.user.id, args._produtos) === true) {
      const msgComplemento = pluralize('os mesmos produtos', args._produtos.length);
      args.self.setState({ loading: false });
      const isOk = await confirmar(`Possível duplicidade detectada! Já existe uma operação igual para ${args.user.nome} com ${msgComplemento} há menos de 5 minutos. Você tem certeza que deseja prosseguir? Só continue se realmente for uma nova inclusão.`);
      if (!isOk) {
        reject(false);
        return;
      }
    }
    // args.self.setState({ loading: true });
    const fila = args.self.state.dataFila;
    let vendedor = await removerVendedor(fila, args.user.id);
    const posicaoAntiga = vendedor.status.order;

    const dataAtual = new Date();
    const inicioAtendimento = vendedor.status.data;
    const fimAtendimento = dataAtual;

    if (vendedor.status.qtdAtendimentos <= 1) {
      reposicionarOrdemNaFila(fila, posicaoAntiga, REDUZIR_FILA);
      console.log("Debugging -- inserirNaFilaDeEspera 4");
      inserirNaFilaDeEspera(vendedor, fila);
      args.self.atualizarColecaoVendedores(args.self, fila)
    } else {
      editAtendimento(args, -1)
    }

    vendedor.backgroundColor = getBackGroundColor(
      vendedor.status.emAtendimento,
      vendedor.status.furouFila
    );

    habilitaSnapshotUpdate(args.self, false);

    delete vendedor.status.serverDate;
    if (vendedor.status.posicaoOriginal === undefined) {
      vendedor.status.posicaoOriginal = vendedor.status.order;
    }

    console.log("Debugging -- collection vendedores 5 -- finalizar atendimento");
    fbDatabase
      .collection("vendedores")
      .doc(vendedor.id)
      .update({
        status: vendedor.status,
      })
      .then(() => {
        habilitaSnapshotUpdate(args.self, true);
      });

    let dadosConversao = {
      vendeu: args.vendeu,
      troca: args.troca !== undefined ? args.troca : false,
      user: {
        ...args.user, inicioAtendimento: inicioAtendimento,
        fimAtendimento: fimAtendimento
      },

      vendaTemporaria: args.vendaTemporaria != undefined ? args.vendaTemporaria : false,
      pecas: args.pecas !== undefined ? args.pecas : 0,
      valor: args.valor !== undefined ? args.valor : 0,
    };

    if (args.experimentou !== undefined) {
      dadosConversao = { ...dadosConversao, experimentou: args.experimentou };
    }

    if (args._produtos) {
      dadosConversao = { ...dadosConversao, produtos: args._produtos };
    }

    if (args._produtosTroca) {
      dadosConversao = { ...dadosConversao, produtosTroca: args._produtosTroca };
    }

    if (!args.vendeu) {
      dadosConversao = {
        ...dadosConversao,
        motivos: args._motivos,
      };
    }

    // dados da indicação
    if (args.indicacao) {
      dadosConversao.indicacao = args.indicacao;
    }

    delete dadosConversao.user.serverDate;

    await registrarConversao(dadosConversao)
      .then(res => {
        //message.info('Venda registrada', 2.5);
        //args.self.setState({ loading: false });
        resolve(true);
      })
      .catch(err => {
        debugger;
        console.log(err);
        // message.error('Erro ao tentar registrar a venda', 2.5);
        // args.self.setState({ loading: false });
        reject(false);
      });
  });
}

export async function getIndicacao(codigo, cpf, telefone) {
  if (!codigo && !cpf && !telefone) {
    return undefined;
  }

  let query = fbDatabase.collection("indicacoes");

  if (telefone) {
    query = query.where("telefone", "==", telefone);
  }

  if (cpf) {
    query = query.where("cpf", "==", cpf);
  }

  if (codigo) {
    query = query.where("codigo", "==", codigo.toUpperCase());
  }

  query = await query.get();

  if (query.empty) {
    return undefined;
  }

  return query.docs[0];
}

export function retornarIndicacao(valor) {

  return new Promise(async (resolve) => {
    const gestorId = await getGestorId();
    const resultados = [];
    const indicacoesRef = fbDatabase.collection("indicacoes");
    let querySnapshot = await indicacoesRef
      .where("codigo", "==", valor)
      .where("pagou", "==", false)
      .where("gestorId", "==", gestorId)
      .where("converteu", "==", false)
      .get();
    querySnapshot.forEach((doc) => {
      resultados.push({
        key: doc.id,
        ...doc.data(),
      });
    });

    querySnapshot = await indicacoesRef
      .where("telefone", "==", valor)
      .where("pagou", "==", false)
      .where("gestorId", "==", gestorId)
      .where("converteu", "==", false)
      .get();

    querySnapshot.forEach((doc) => {
      resultados.push({
        key: doc.id,
        ...doc.data(),
      });
    });

    querySnapshot = await indicacoesRef.where("cpf", "==", valor)
      .where("pagou", "==", false)
      .where("gestorId", "==", gestorId)
      .where("converteu", "==", false)
      .get();

    querySnapshot.forEach((doc) => {
      resultados.push({
        key: doc.id,
        ...doc.data(),
      });
    });

    resolve(resultados[0]);
  });
}

function reconfigurarStatusVendedor(
  vendedor,
  {
    posicao = AUSENTE,
    posicaoOriginal,
    order = 0,
    descricao = null,
    icon = "clock-o",
    data = undefined,
    emAtendimento = false,
    furouFila = false,
    qtdAtendimentos = undefined
  }
) {


  console.log("Debugging -- reconfigurarStatusVendedor");

  vendedor.status.posicao = posicao;
  vendedor.status.order = order;
  vendedor.status.icon = icon;
  vendedor.status.data = data === undefined ? new Date() : data;
  vendedor.status.serverDate = fbFirestore.FieldValue.serverTimestamp();
  vendedor.status.emAtendimento = emAtendimento;
  vendedor.status.furouFila = furouFila;
  vendedor.status.qtdAtendimentos = qtdAtendimentos === undefined ? 1 : qtdAtendimentos;

  modificarLegendaFila(vendedor, descricao);
}

function modificarLegendaFila(vendedor, descricao = null) {
  console.log("Debugging -- modificarLegendaFila");
  vendedor.status.descricao =
    descricao === null ? `${vendedor.status.order + 1}º da fila` : descricao;
}

export async function ausentarVendedor(self, user, icon, reason) {

  console.log("Debugging -- ausentarVendedor");

  return new Promise((resolve, reject) => {
    const order = user.status.order;
    const fila = self.state.dataFila;
    const vendedor = removerVendedor_v2(fila, user.id);

    reconfigurarStatusVendedor(vendedor, {
      descricao: reason,
      icon: icon,
    });

    habilitaSnapshotUpdate(self, false);

    delete vendedor.status.serverDate;

    if (vendedor.status.posicaoOriginal === undefined) {
      delete vendedor.status.posicaoOriginal;
    }

    console.log("Debugging -- collection vendedores 6 -- ausentarVendedor");
    fbDatabase
      .collection("vendedores")
      .doc(vendedor.id)
      .update({
        status: vendedor.status,
      })
      .then(async () => {
        habilitaSnapshotUpdate(self, true);

        await criarHistorico(vendedor);
        reposicionarOrdemNaFila(fila, order);
        self.atualizarColecaoVendedores(self, fila);
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function criarHistorico(vendedor) {
  console.log("Debugging -- criarHistorico");
  const docRef = await fbDatabase
    .collection("historico")
    .add({
      dataSaida: vendedor.status.data,
      gestorId: vendedor.gestorId,
      empresaId: vendedor.empresa.id,
      motivo: vendedor.status.descricao,
      vendedorId: vendedor.id
    });


  console.log("Debugging -- collection vendedores 7 -- criarHistorico");
  await fbDatabase
    .collection("vendedores")
    .doc(vendedor.id)
    .update({
      "status.ultimaSaidaId": docRef.id
    })

  return docRef.id;
}

export function carregarTabelasAuxiliares(callback) {
  console.log("Debugging -- carregarTabelasAuxiliares");
  return new Promise(async (resolve) => {
    const gestorId = await getGestorId();



    //No more Products Load
    //Pegar apenas um produto para verificar se existe algum produto cadastrado na conta7
    let existemProdutos = filaStorage.getWithExpiry('existemProdutos');
    if (!existemProdutos) {
      const produtos = await fbDatabase
        .collection("produtos")
        .where("gestorId", "==", gestorId)
        .where("ativo", "==", true)
        .orderBy("nome")
        .limit(1)
        .get();

      // for (let i = 0; i < produtos.size; i++) {
      //   const nome = produtos.docs[i].data().nome.toLowerCase();
      existemProdutos = !produtos.empty;
      filaStorage.setWithExpiry('existemProdutos', existemProdutos);
    }


    let listaMotivos = filaStorage.getWithExpiry('motivos');
    if (!listaMotivos) {
      const motivos = await fbDatabase
        .collection("motivos")
        .where("gestorId", "==", gestorId)
        .where("ativo", "==", true)
        .orderBy("nome")
        .get();


      listaMotivos = [];
      for (let i = 0; i < motivos.size; i++) {
        const nome = motivos.docs[i].data().nome;

        listaMotivos.push({
          key: motivos.docs[i].id,
          value: nome,
        });
      }
      filaStorage.setWithExpiry('motivos', listaMotivos);
    }

    if (callback) {
      resolve(callback(existemProdutos, listaMotivos));
    } else {
      resolve();
    }
  });
}


export async function carregarDadosRanking(diferencaMes = 0) {
  console.log("Debugging -- carrergarDadosRanking");

  const empresaId = await getEmpresaId();

  // const data = moment().subtract(diferencaMes, "months");
  const data = subMonths(new Date(), diferencaMes);

  // const inicio = data.startOf("month").toDate();
  const inicio = startOfMonth(data);

  // const fim = data.endOf("month").toDate();
  const fim = endOfMonth(data);

  const rankingSnapshot = await fbDatabase
    .collection("ranking")
    .where("empresaId", "==", empresaId)
    .where("data", ">=", inicio)
    .where("data", "<=", fim)
    .get();

    console.log("Debugging -- collection vendedores 8 -- carregarDadosRanking");
  const snapshot = await fbDatabase
    .collection("vendedores")
    .where("empresa.id", "==", empresaId)
    .get();

  const vendedores = [];
  snapshot.docs.forEach(vendedor => {
    const vend = {
      id: vendedor.id,
      avatar: vendedor.data().avatar,
      apelido: vendedor.data().apelido
    };
    vendedores.push(vend);
  });

  const dadosRanking = rankingSnapshot.docs.map((item) => item.data());
  const reduceRanking = dadosRanking.reduce((resultado, item) => {
    const id = item.vendedorId;

    resultado[id] = resultado[id] || {
      valorTotal: 0,
      nome: vendedores.find((item) => item.id === id).apelido,
      avatar: vendedores.find((item) => item.id === id).avatar,
    };

    resultado[id].valorTotal += item.atendimentos.valor;

    return resultado;
  }, {});

  return reduceRanking;
}

export async function extrairListaRanking(dados) {
  console.log("Debugging -- extraListaRanking");
  const entries = Object.entries(dados);

  // Ordena a lista pelo valor total, em ordem decrescente
  const resultadoOrdenado = entries.sort(
    (a, b) => b[1].valorTotal - a[1].valorTotal
  );

  const lista = resultadoOrdenado.map((item) => ({
    avatar: item[1].avatar,
    nome: item[1].nome,
    valorTotal: parseFloat(item[1].valorTotal.toFixed(2)),
  }));

  return lista;
}

export function extrairDataset(dadosVendedores) {
  console.log("Debugging -- extrairDataset");
  const entries = Object.entries(dadosVendedores);

  // Ordena a lista pelo valor total, em ordem decrescente
  const resultadoOrdenado = entries.sort(
    (a, b) => b[1].valorTotal - a[1].valorTotal
  );

  const labels = resultadoOrdenado.map((item) => item[1].nome);
  const valores = resultadoOrdenado.map((item) =>
    parseFloat(item[1].valorTotal.toFixed(2))
  );

  const dataset = [
    {
      // label: "Valor em venda (R$)",
      data: valores,
      // backgroundColor: "#70CAD1"
      backgroundColor: "rgba(0, 143, 251, 0.85)",
    },
  ];

  const dados = {
    labels: labels,
    datasets: dataset,
  };

  return dados;
}

export async function retornarGerente(cpf) {
  console.log("Debugging -- retornarGerente");
  const gestorId = await getGestorId();

  const snapshot = await fbDatabase
    .collection("gerentes")
    .where("gestorId", "==", gestorId)
    .where("ativo", "==", true)
    .get();

  let dadosGerente = null;

  snapshot.forEach((doc) => {
    const cpfNoBanco = doc.data().cpf;
    const cpfNoBancoSemPontuacao = cpfNoBanco.replace(/[^\d]/g, '');
    
    if (cpfNoBancoSemPontuacao.startsWith(cpf.slice(0, 6))) {
      dadosGerente = doc.data();
      return;
    }
  });

  return dadosGerente;
}



export function formatarNome(nome) {
  const nomes = nome.split(' ');

  let nomeCompleto = '';

  for (let i of nomes) {
    const formatedName = i.charAt(0).toUpperCase() + i.slice(1).toLowerCase();
    nomeCompleto = nomeCompleto + ' ' + formatedName;
  }

  return nomeCompleto.substring(1);
}