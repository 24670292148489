import React, { useCallback, useState } from 'react';
import { Avatar, Modal, Button, Radio, Descriptions, message } from 'antd';
import IconFont from "../../iconFont/IconFont";
import './Styles.css';


const SairDaFila = ({ visible, onCancel, onSubmit, vendedor }) => {

    const [name, setName] = useState();
    const [loading, setLoading] = useState(false);

    const valueOptions = [
        { type: "home", value: "casa" },
        { type: "man-woman", value: "banheiro" },
        { type: "lunch", value: "almoço" },
        { type: "coffee", value: "café" },
        { type: "street-view", value: "outro" },
    ];

    const handleRadioGroupChange = useCallback(({ target }) => {
        const findTarget = valueOptions.find(
            (value) => value.type === target.value
        );

        setName(findTarget.type);
    }, [valueOptions]);


    const handleOk = async () => {

        if (!name) {
            message.warning('Escolha o motivo da sua saída');
            return;
        }
        setLoading(true);
        const razao = valueOptions.filter(item => item.type === name)[0];
        await onSubmit(razao);
        setLoading(false);
        setName(undefined);
    }

    const handleCancel = () => {
        setLoading(false);
        onCancel()
    }

    return (
        <div>
            <Modal
                title={`${vendedor.apelido}, saída`}
                open={visible}
                onCancel={handleCancel}
                footer={[
                    <Button
                        key="back"
                        onClick={handleCancel}
                        disabled={loading}
                    >
                        Cancelar
                    </Button>,
                    <Button
                        key="submit"
                        type="danger"
                        disabled={loading}
                        loading={loading}
                        onClick={handleOk}                    >
                        {loading ? 'Saindo...' : 'Sair da Fila'}
                    </Button>,
                ]}
            >

                <div className="divReasonToLeave">

                    <Avatar
                        src={vendedor.avatar}
                        size={52}
                        className='avatarSaidaVendedor'
                    />

                    <Descriptions title="Diga por que está saindo"></Descriptions>
                    <Radio.Group 
                    size="large" 
                    onChange={handleRadioGroupChange} 
                    value={name}
                    >
                        <Radio.Button className='radio-group-opcao-sair-fila' value="home">
                            <IconFont type={"home"} size={24} />
                            Casa
                        </Radio.Button>
                        {/* <Radio.Button value="man-woman">
                            <IconFont type="man-woman" size={24} />
                            Banheiro
                        </Radio.Button> */}
                        <Radio.Button className='radio-group-opcao-sair-fila' value="lunch">
                            <IconFont type="lunch" size={24} />
                            Almoço
                        </Radio.Button>
                        <Radio.Button className='radio-group-opcao-sair-fila' value="coffee">
                            <IconFont type="coffee" size={24} />
                            Café
                        </Radio.Button>
                        <Radio.Button className='radio-group-opcao-sair-fila' value="street-view">
                            <IconFont type="street-view" size={24} />
                            Outro
                        </Radio.Button>
                    </Radio.Group>
                </div>


            </Modal>

        </div>
    );

}

export default SairDaFila