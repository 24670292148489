import { fbDatabase, fbFirestore } from "../../firebase/firebase";
import {
  getEmpresaId,
} from "../../components/auth/auth-provider";

export async function carregarDadosRanking() {
  const empresaId = await getEmpresaId();

  const rankingSnapshot = await fbDatabase
    .collection("ranking")
    .where("empresaId", "==", empresaId)
    .get();

  /** X-TUDO Completo */
  let xRef = fbDatabase.collection('x-tudo').doc('FunctionsRankingJSX-carregarDadosRanking');
  xRef.set({
    collection: 'ranking',
    lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
    count: fbFirestore.FieldValue.increment(rankingSnapshot.size),
    lastIncrement: rankingSnapshot.size
  }, { merge: true }).catch(error => console.error(error));

  const dadosRanking = rankingSnapshot.docs.map((item) => item.data());
  const reduceRanking = dadosRanking.reduce((resultado, item) => {
    const id = item.vendedorId;

    resultado[id] = resultado[id] || {
      valorTotal: 0,
      nome: this.state.vendedores.find((item) => item.id === id).apelido,
      avatar: this.state.vendedores.find((item) => item.id === id).avatar,
    };
    resultado[id].valorTotal += item.atendimentos.valor;

    return resultado;
  }, {});

  const entries = Object.entries(reduceRanking);
  const resultadoOrdenado = entries.sort(
    (a, b) => b[1].valorTotal - a[1].valorTotal
  );

  const labels = resultadoOrdenado.map((item) => item[1].nome);
  const valores = resultadoOrdenado.map((item) =>
    parseFloat(item[1].valorTotal.toFixed(2))
  );

  const dataset = [
    {
      // label: "Valor em venda (R$)",
      data: valores,
      // backgroundColor: "#70CAD1"
      backgroundColor: "rgba(0, 143, 251, 0.85)",
    },
  ];

  const dados = {
    avatar: resultadoOrdenado.map((item) => item[1].avatar),
    labels: labels,
    datasets: dataset,
  };

  return dados;
}
