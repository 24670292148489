import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router";
import ls from 'local-storage'
import Fila from "./pages/fila/Fila";
import Login from "./pages/login/Login";
import Ranking from "./pages/ranking/Ranking";


class Routes extends React.Component {
    render() {    
        return (
            <Switch>
                <PrivateRoute exact path="/fila">
                    <Fila/>
                </PrivateRoute>

                <PrivateRoute exact path="/ranking">
                    <Ranking/>
                </PrivateRoute>

                <Route path="/login" component={Login} />
                <Redirect from="*" to="/fila" />
            </Switch>
        );
    }
}

export default withRouter(Routes);


function PrivateRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) => {
                const user = ls.get('loja-fila');
                if (user) {
                    return children;
                }

                return <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location }
                    }}
                />
            }}
        />
    );
}
