import React from 'react';
import AdicionarVendedorFila from './AdicionarVendedorFila';
import { adicionarVendedoresNaFilaEmLote } from '../../pages/fila/Functions-Fila';

function AdicionarVendedorFilaWrapper(props) {
  const { mostrarAdicionarVendedor } = props;

  return (
    <>
      {mostrarAdicionarVendedor && (
        <AdicionarVendedorFila
          visible={mostrarAdicionarVendedor}
          onCancel={() => props.setMostrarAdicionarVendedor(false)}
          onSubmit={async (checkedList) => {
            return new Promise(async (resolve, reject) => {
              await adicionarVendedoresNaFilaEmLote(
                checkedList,
                props.dataFila,
                props.dataAusente
              );

              setTimeout(() => {
                resolve(true);
                props.setMostrarAdicionarVendedor(false);
              }, 2000);
            });
          }}
          data={props.dataAusente}
        />
      )}
    </>
  );
}

export default AdicionarVendedorFilaWrapper;
