import React, { useState, useEffect } from 'react';
import { Typography, Modal, Button, Spin, Progress, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { avisar, finalizarAtendimento, formatarMoeda, retornarIndicacao } from '../../../pages/fila/Functions-Fila';
import ProdutosAtendimento from './Paginas/Produtos';
import MotivosAtendimento from './Paginas/Motivos';
import IndicacaoVenda from './Paginas/Indicacao';
import TimeLineAtendimento from './Paginas/Timeline';
import './Styles.css';
import ForaMix from './Paginas/ForaMix';


const EncerrarAtendimento = (props) => {
    const STEP_0_FORA_MIX = 0;
    const STEP_1_MOTIVOS = 1;
    const STEP_2_ESTORNO_PRODUTOS = 2;
    const STEP_3_PRODUTOS = 3;
    const STEP_4_INDICACAO = 4;
    const STEP_5_TIMELINE = 5;
    const STEP_6_FIM = 6;


    const [tipoOperacao, setTipoOperacao] = useState(props.tipoEncerramento);
    const exibirIndicador = props.self.state.config.exibirIndicador;

    let step = 0;
    let titulo = '';

    if (tipoOperacao === 'vendeu' && !props.self.state.config.vendaTemporaria) {
        step = STEP_3_PRODUTOS;
        titulo = 'Quais produtos vendeu?';
    } else if (tipoOperacao === 'vendeu') {
        step = STEP_5_TIMELINE;
    } else if (tipoOperacao === 'nao-vendeu') {
        step = STEP_0_FORA_MIX;
        titulo = 'Quais produtos não vendeu?'
    } else if (tipoOperacao === 'troca') {
        step = STEP_2_ESTORNO_PRODUTOS;
        titulo = 'Vendeu algo durante a troca?'
    } else {
        step = STEP_5_TIMELINE;
    }

    if (tipoOperacao === 'troca') step = STEP_2_ESTORNO_PRODUTOS;

    const [currentStep, setCurrentStep] = useState(step);
    const [produtosTitulo] = useState(titulo);

    const [produtos, setProdutos] = useState([]);
    const [produtosTroca, setProdutosTroca] = useState([]);

    const [salvandoDados, setSalvandoDados] = useState(true);
    const [statusFinalizacao, setStatusFinalizacao] = useState('Salvando...');
    const [motivosSelecionados, setMotivosSelecionados] = useState([]);
    const [foraMix, setForaMix] = useState(false);
    const [buscaForaMix, setBuscaForaMix] = useState(props.self.state.config.foraMixCadastro);


    const [experimentou, setExperimentou] = useState(undefined);
    const [timelineData, setTimelineData] = useState([]);
    const [clienteIndicado, setClienteIndicado] = useState(false);
    const [tipoBotaoProximo, setTipoBotaoProximo] = useState('primary');
    const [labelBotaoProximo, setLabelBotaoProximo] = useState('Próximo');
    const [labelBotaoVoltar, setLabelBotaoVoltar] = useState('Anterior');
    const [indicacao, setIndicacao] = useState('');
    const [indicacaObj, setIndicacaoObj] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [mensagemFinal, setMensagemFinal] = useState('');
    const [operacaoFinalizada, setOperacaoFinalizada] = useState(false);
    const [progressColor, setProgressColor] = useState('green');
    const [statusOperacao, setStatusOperacao] = useState(null);

    const [contador, setContador] = useState(5);
    const [executandoTimer, setExecutandoTimer] = useState(false);


    const titles = [
        'Início',
        'Escolha os motivos da perda de venda',
        `O que foi trocado?`,
        `${produtosTitulo}`,
        "Houve indicação de Parceiro?",
        "Você disse que",
        `${statusFinalizacao}`
    ];
    const title = titles[currentStep];


    useEffect(() => {
        let intervalId;

        if (executandoTimer) {
            intervalId = setInterval(() => {
                setContador(prevCount => prevCount - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [executandoTimer]);

    useEffect(() => {
        console.log('useEffect: count: ' + contador);
        if (contador <= 0) {
            setExecutandoTimer(false);
            handleOk();
        }
    }, [contador]);


    const startTimer = () => {
        setContador(5);
        setExecutandoTimer(true);
    };



    useEffect(() => {
        gerarTimeline();
        setTipoOperacao(tipoOperacao);
        if (currentStep >= STEP_5_TIMELINE) {
            setTipoBotaoProximo('danger');
            setLabelBotaoProximo('Sim, finalizar');
            setLabelBotaoVoltar('Não');
        } else {
            setTipoBotaoProximo('primary');
            setLabelBotaoProximo('Próximo');
            setLabelBotaoVoltar('Anterior');
        }
    }, [currentStep, tipoOperacao]);

    const exibirBotaoVoltar = () => {

        if (currentStep === STEP_6_FIM) return false;

        if (tipoOperacao === 'vendeu') {
            return !props.self.state.config.vendaTemporaria && (currentStep > STEP_3_PRODUTOS);
        } else if (tipoOperacao === 'troca') {
            return (currentStep > STEP_2_ESTORNO_PRODUTOS);
        } else if (tipoOperacao === 'outros') {
            return false;
        }

        //currentStep >=  STEP_MOTIVOS && currentStep < STEP_FIM
        return currentStep > 0;
    }

    const desabilitarBotaoProximo = () => {
        if (currentStep === STEP_0_FORA_MIX) return foraMix === undefined;


        if (currentStep === STEP_1_MOTIVOS) return motivosSelecionados.length === 0 || experimentou === undefined;
        if (currentStep === STEP_3_PRODUTOS) return props.self.state.config.registroProdutos && produtos.length === 0;
        if (currentStep === STEP_2_ESTORNO_PRODUTOS) return props.self.state.config.registroProdutos && produtosTroca.length === 0;

        return loading;
    }

    const salvarOperacao = async () => {
        setSalvandoDados(true);

        let mensagemFinal = '';
        const payload = {};

        payload.self = props.self;
        payload.user = props.vendedor;

        const prds = produtos.map(obj => {
            return {
                id: obj.key,
                nome: obj.nome,
                qtd: obj.qtd,
                valorVenda: obj.valorVenda,
                codigo: obj.codigo ? obj.codigo : '',
            };
        });

        payload._produtos = prds;
        payload.vendaTemporaria = tipoOperacao === 'vendeu' && props.self.state.config.vendaTemporaria;

        if (tipoOperacao === 'vendeu') {
            if (!payload.vendaTemporaria) {
                payload.pecas = calcularTotalPecas();
                payload.valor = calcularValorTotal(produtos);
                if (indicacaObj) {
                    payload.indicacao = {
                        id: indicacaObj.key,
                        indicador: indicacaObj.indicador
                    }
                }
            }
            payload.vendeu = true;
            mensagemFinal = 'Sua venda foi registrada.';
            setProgressColor('green');
            setStatusOperacao('');
        } else if (tipoOperacao === 'nao-vendeu') {

            debugger;

            const motivos = motivosSelecionados && motivosSelecionados.length > 0 ? motivosSelecionados.map(obj => {
                return { id: obj.key, nome: obj.value };
            }) : [{ id: '--', nome: 'Fora de Mix' }];

            payload._motivos = motivos;
            payload.vendeu = false;
            payload.experimentou = experimentou;
            mensagemFinal = 'Perda de oportunidade registrada.';
            setProgressColor('green');
            setStatusOperacao('');
        } else if (tipoOperacao === 'troca') {
            const valorConversao = (calcularValorTotal(produtos) - calcularValorTotal(produtosTroca));
            payload.troca = true;
            payload._produtosTroca = produtosTroca;
            payload.valor = valorConversao;
            payload.pecas = calcularTotalPecas();

            payload.vendeu = valorConversao > 0;

            mensagemFinal = valorConversao > 0 ? 'Troca com venda registrada.' : 'Troca sem venda registrada.';
            setProgressColor('green');
            setStatusOperacao('');
        } else {
            payload.voltarParaPrimeiraPosicao = true;
            mensagemFinal = 'Você voltou para sua posição original na fila.';
            setProgressColor('green');
            setStatusOperacao('');
        }

        finalizarAtendimento(payload)
            .then(ret => {
                setSalvandoDados(false);
                setStatusFinalizacao('Obrigado!');
                setOperacaoFinalizada(true);
                setMensagemFinal(mensagemFinal);
                startTimer();
            })
            .catch(err => {
                setStatusFinalizacao('Operação Cancelada!');
                setMensagemFinal('Você não concluiu esta operação.');
                setSalvandoDados(false);
                setOperacaoFinalizada(false);
            });
    }

    const handleNext = async () => {
        switch (currentStep) {
            case STEP_1_MOTIVOS:
                if (props.self.state.config.registroProdutos && motivosSelecionados.length === 0) {
                    avisar('Informe um ou mais motivos envolvidos neste atendimento.');
                    return;
                }
                break;
            case STEP_3_PRODUTOS:
                if (props.self.state.config.registroProdutos && produtos.length === 0) {
                    avisar('Informe um ou mais produtos envolvidos neste atendimento.');
                    return;
                }
                break;
            case STEP_4_INDICACAO:
                if (indicacao !== '') {
                    setLoading(true);
                    const indicacaoObj = await retornarIndicacao(indicacao);
                    setLoading(false);
                    if (indicacaoObj.length === 0) {
                        message.error('Indicação não localizada');
                        return;
                    }
                    setIndicacaoObj(indicacaoObj);
                    setClienteIndicado(true);
                }
                break;
            default:
                break;
        }

        let nexStep = 0;

        if (currentStep === STEP_0_FORA_MIX) {
            nexStep = foraMix ? STEP_3_PRODUTOS : STEP_1_MOTIVOS;
        } else if (currentStep === STEP_1_MOTIVOS) {
            if (tipoOperacao === 'nao-vendeu') {
                nexStep = STEP_3_PRODUTOS;
            } else if (currentStep === 'troca') {
                nexStep = STEP_1_MOTIVOS;
            }
        } else if (currentStep === STEP_2_ESTORNO_PRODUTOS) {
            nexStep = STEP_3_PRODUTOS;
        } else if (currentStep === STEP_3_PRODUTOS) {
            nexStep = tipoOperacao === 'vendeu' ? nexStep = exibirIndicador ? STEP_4_INDICACAO : STEP_5_TIMELINE : STEP_5_TIMELINE;
        } else if (currentStep === STEP_4_INDICACAO) {
            nexStep = STEP_5_TIMELINE;
        }

        gerarTimeline();

        if (currentStep === STEP_5_TIMELINE) {
            nexStep = STEP_6_FIM;
            salvarOperacao();
        }
        setCurrentStep(nexStep);
    };


    const handlePrev = () => {
        let priorStep = STEP_6_FIM;

        if (currentStep === STEP_5_TIMELINE) {
            priorStep = tipoOperacao !== 'vendeu' ? STEP_3_PRODUTOS : exibirIndicador ? STEP_4_INDICACAO : STEP_3_PRODUTOS;
        } else if (currentStep === STEP_4_INDICACAO) {
            priorStep = STEP_3_PRODUTOS;
        } else if (currentStep === STEP_3_PRODUTOS) {
            if (tipoOperacao === 'nao-vendeu') {
                priorStep = foraMix ? STEP_0_FORA_MIX : STEP_1_MOTIVOS;
            } else {
                priorStep = STEP_2_ESTORNO_PRODUTOS;
            }
        } else if (currentStep === STEP_2_ESTORNO_PRODUTOS || (currentStep === STEP_5_TIMELINE && tipoOperacao === 'troca')) {
            priorStep = STEP_2_ESTORNO_PRODUTOS;
        } else if (currentStep === STEP_1_MOTIVOS) {
            priorStep = STEP_0_FORA_MIX;
        }
        setCurrentStep(priorStep);
    };


    const handleCancel = () => {
        setCurrentStep(0);
        props.onCancel();
    };

    const handleOk = () => {
        setCurrentStep(0);
        props.onCancel();
    };

    const handleProdutoSelecionado = (value) => {
        const novoProduto = {
            key: value.key,
            nome: value.produto,
            valorVenda: value.valorUnitario,
            qtd: value.qtd,
            codigo: value.codigo ? value.codigo : '',
        };

        const atualizarProdutos = [...produtos, novoProduto];
        setProdutos(atualizarProdutos);

    };


    const handleProdutoTroca = (value) => {
        const novoProduto = {
            key: value.key,
            nome: value.produto,
            valorVenda: value.valorUnitario,
            qtd: value.qtd,
            codigo: value.codigo ? value.codigo : '',
        };
        const atualizarProdutos = [...produtosTroca, novoProduto];
        setProdutosTroca(atualizarProdutos);
    }

    const gerarTimeline = () => {
        const timelineData = [];
        if (tipoOperacao === 'vendeu') {
            if (!props.self.state.config.vendaTemporaria) {

                timelineData.push({
                    color: 'blue',
                    value: 'fez uma venda'
                });

                timelineData.push({
                    color: produtos.length > 0 ? 'blue' : 'red',
                    value: produtos.length > 0 ? `vendeu ${produtos.length} ${produtos.length > 1 ? 'produtos' : 'produto'} com ${calcularTotalPecas()} ${calcularTotalPecas() > 1 ? 'peças' : 'peça'} no valor de ${formatarMoeda(calcularValorTotal(produtos))}` :
                        'não informou produtos (isso impactará diversos relatórios do Bluve, inclusive suas Metas, se houver).'
                });

                timelineData.push({
                    color: clienteIndicado ? 'blue' : 'red',
                    value: clienteIndicado ? 'a venda foi indicação de parceiros' : 'a venda não foi indicação de parceiros',
                });
            } else {
                timelineData.push({
                    color: 'blue',
                    value: 'fez uma venda. Produtos e indicação (se houver) serão enviados para o Bluve pelo PDV.',
                });
            }
        } else if (tipoOperacao === 'nao-vendeu') {
            timelineData.push({
                color: 'red',
                value: 'não vendeu'
            });

            timelineData.push({
                color: produtos.length > 0 ? 'blue' : 'red',
                value: produtos.length > 0 ? `${produtos.length} ${produtos.length > 1 ? 'produtos' : 'produto'}` :
                    'não informou produtos (isso impactará diversos relatórios do Bluve, inclusive suas Metas, se houver).'
            });

            timelineData.push({
                color: 'blue',
                value: motivosSelecionados.length > 1 ? `pelos motivos: ${retornarMotivosStr()}` : `pelo motivo: ${retornarMotivosStr()}`
            });

            timelineData.push({
                color: 'blue',
                value: experimentou ? `cliente experimentou algo` : `cliente não experimentou nada`
            });

        } else if (tipoOperacao === 'troca') {
            timelineData.push({
                color: 'green',
                value: 'fez uma troca'
            });

            const valor = (calcularValorTotal(produtos) - calcularValorTotal(produtosTroca));

            if (valor > 0) {
                console.log(valor);
                timelineData.push({
                    color: 'green',
                    value: `fez uma troca com venda no valor de ${formatarMoeda(valor)}`
                });
            } else {
                timelineData.push({
                    color: 'red',
                    value: `fez uma troca sem venda`
                });
            }
        } else {
            timelineData.push({
                color: 'yellow',
                value: 'não fez atendimento'
            });
        }

        setTimelineData(timelineData);
    }

    const handleRemoverProduto = (produtoId) => {
        setProdutos(produtos.filter((product) => product.key !== produtoId));
    };

    const calcularValorTotal = (data) => {
        return data.reduce((total, product) => total + product.valorVenda * product.qtd, 0);
    };

    const calcularTotalPecas = () => {
        return produtos.reduce((total, product) => total + product.qtd, 0);
    };

    const retornarMotivosStr = () => {
        const motivos = [];
        motivosSelecionados.forEach(motivo => {
            motivos.push(motivo.value);
        });

        const motivosString = motivos.join(', ');
        const ultimaVirgulaIndex = motivosString.lastIndexOf(',');

        if (ultimaVirgulaIndex === -1) return motivosString;

        const motivosStringFinal = motivosString.substring(0, ultimaVirgulaIndex) + ' e' + motivosString.substring(ultimaVirgulaIndex + 1);
        return motivosStringFinal;
    }

    // const retornarCorTipoOperacao = () => {
    //     if (tipoOperacao === 'vendeu') return 'green';
    //     if (tipoOperacao === 'nao-vendeu') return 'red';
    //     if (tipoOperacao === 'troca') return 'blue';
    // }

    const handleMultiplosMotivosChange = (value, obj) => {
        const motivosSelecionados = [];
        obj.map((item) => {
            const motivoSelecionado = {
                key: item.key,
                value: item.value
            }
            motivosSelecionados.push(motivoSelecionado);
        });
        setMotivosSelecionados(motivosSelecionados);
    }

    const handleMotivosSelectChange = (tag, checked) => {
        const nextSelectedTags = checked ? [...motivosSelecionados, tag] : motivosSelecionados.filter(t => t !== tag);
        setMotivosSelecionados(nextSelectedTags);
    }

    const handleExperimentouChange = (value) => {
        setExperimentou(value);
    }

    const handleForaMixChange = (value) => {
        setForaMix(value);
    }

    const handleIndicacaoChange = (obj) => {
        setIndicacao(obj.target.value);
    }

    let height = 500;
    if (window.innerHeight >= 795) {
        height = 600;
    }

    if (window.innerHeight >= 923) {
        height = 750;
    }

    const modalBodyStyle = { height: height };


    if (props.tipoEncerramento === 'Venda') {
        //configurarTipoEncerramento(props.tipoEncerramento);
        //setTipoOperacao('vendeu');
    }

    return (
        <Modal
            className='encerrar-atendimento-modal'
            maskTransitionName="slide-down"
            bodyStyle={modalBodyStyle}
            destroyOnClose
            wrapClassName="rounded-modal"
            centered={true}
            maskClosable={true}
            closable={true}
            title={
                <>
                    <Typography.Title level={4}>
                        {title}
                    </Typography.Title>

                </>
            }
            open={props.visible}
            onCancel={handleCancel}
            onOk={handleOk}
            footer={
                <div>
                    {(exibirBotaoVoltar()) && (
                        <Button onClick={handlePrev}>{labelBotaoVoltar}</Button>
                    )}
                    {currentStep < STEP_6_FIM ? (
                        <Button
                            type={tipoBotaoProximo}
                            onClick={handleNext}
                            disabled={desabilitarBotaoProximo()}
                            loading={loading}>{labelBotaoProximo}</Button>
                    ) : (
                        <Button disabled={salvandoDados} onClick={handleOk}>Concluir</Button>
                    )}
                </div>
            }
        >
            <div className="breadcrumbs">

                {currentStep === STEP_0_FORA_MIX && (
                    <ForaMix
                        foraMix={foraMix}
                        handleForaMixChange={handleForaMixChange}
                    />
                )}

                {currentStep === STEP_1_MOTIVOS && (
                    <MotivosAtendimento
                        experimentou={experimentou}
                        motivos={props.motivos}
                        handleMotivosSelectChange={handleMotivosSelectChange}
                        handleMultiplosMotivosChange={handleMultiplosMotivosChange}
                        handleExperimentouChange={handleExperimentouChange}
                        motivosSelecionados={motivosSelecionados}
                    />
                )}


                {currentStep === STEP_2_ESTORNO_PRODUTOS && (
                    <ProdutosAtendimento
                        handleProdutoSelecionado={handleProdutoTroca}
                        handleRemoverProduto={handleRemoverProduto}
                        vendedor={props.vendedor}
                        produtos={produtosTroca}
                        tipoOperacao={tipoOperacao}
                        foraMix={foraMix}
                        buscaForaMix={buscaForaMix}
                    />
                )}

                {currentStep === STEP_3_PRODUTOS && (
                    <ProdutosAtendimento
                        handleProdutoSelecionado={handleProdutoSelecionado}
                        handleRemoverProduto={handleRemoverProduto}
                        vendedor={props.vendedor}
                        produtos={produtos}
                        tipoOperacao={tipoOperacao}
                        foraMix={foraMix}
                        buscaForaMix={buscaForaMix}
                    />
                )}


                {currentStep === STEP_4_INDICACAO && (
                    <IndicacaoVenda
                        handleIndicacaoChange={handleIndicacaoChange}
                        desabilitar={loading}
                    />
                )}

                {currentStep === STEP_5_TIMELINE && (
                    <TimeLineAtendimento timelineData={timelineData} />
                )}

                {currentStep === STEP_6_FIM && (
                    <div>
                        {salvandoDados === true ? (
                            <div className="infinite-progress">
                                <Spin indicator={<LoadingOutlined />} size='large' />
                            </div>

                        ) : (
                            <div>
                                {operacaoFinalizada === true ? (
                                    <Progress
                                        className='step5-finalizar-progress'
                                        strokeColor={progressColor}
                                        success={statusOperacao}
                                        type="circle"
                                        percent={100} />
                                ) : (
                                    <Progress className='step5-finalizar-progress' type="circle" status="exception" percent={70} />
                                )}

                                <br></br>
                                <label className='step5-finalizar-label'>{mensagemFinal}</label>

                                <br></br>
                                <span className='step5-timer'>{`Fechamento automático em 0${contador} segundos`}</span>
                            </div>
                        )}
                    </div>
                )}

            </div>

        </Modal>
    );
};

export default EncerrarAtendimento;
