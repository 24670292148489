import { Component } from "react";
import { H_01_HORA } from "../funcs/constants";


class FilaStorage extends Component {

    get(key) {
        const storage = localStorage.getItem(key);
        if (storage === 'undefined') {
            return undefined
        }
        return storage;
    }

    set(key, value) {
        localStorage.setItem(key, value);
    }

    setWithExpiry(key, value, ttl) {
        if (!ttl) {
            ttl = H_01_HORA;
        }
        const now = new Date();
        const item = {
            value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item));
    }

    getWithExpiry(key) {
        const itemStr = this.get(key);
        if (!itemStr || itemStr === 'null') {
            return undefined;
        }

        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return undefined;
        }
        return item.value;
    }

    clear(name) {
        localStorage.clear(name)
    }

    clearAll() {
        localStorage.clear();
    }

    getBuscaPorCodigo() {
        return this.get('@filaApp/buscaPorCodigo');
    }

    setBuscaPorCodigo(porCodigo) {
        this.set('@filaApp/buscaPorCodigo', porCodigo);
    }
}

const filaStorage = new FilaStorage();
export default filaStorage;