import React from 'react';
import { Form, Input, Button, Modal } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { autenticar, redirectJustIfUserIsAuthenticated } from './LoginF'
import { RECAPTCHA_SITE_KEY } from '../../firebase/firebase';

import history from '../../history';
import ReCAPTCHA from 'react-google-recaptcha';
import API from '../../services/api/api';

import './Login.css';
import "antd/dist/antd.css";
import mensagem from '../../components/message/Message';

const api = new API();
const params = new URLSearchParams(window.location.search);


class Login extends React.Component {

  state = {
    loading: false,
    reCaptchaToken: undefined,
    disableLoginPermanently: false,
    passType: 'password',
    passClass: 'zmdi zmdi-eye',
    login: ''
  }

  constructor(props) {
    super(props);
    this.autenticar = autenticar.bind(this);
    this.redirectJustIfUserIsAuthenticated = redirectJustIfUserIsAuthenticated.bind(this);
    this.esqueciSenhaOnClick = this.esqueciSenhaOnClick.bind(this);
    this.handleReCaptcha = this.handleReCaptcha.bind(this);
    this.desabilitarBotoesLogin = this.desabilitarBotoesLogin.bind(this);
    this.redirectJustIfUserIsAuthenticated();
  };

  componentDidMount() {
    if (params.get('fromBluve')) {
      mensagem.avisar('Você foi redirecionado para o Login do Fila. Digite sua senha.');
    }
  }

  warning(message) {
    Modal.warning({
      title: 'Aviso',
      content: message,
    });
  }

  esqueciSenhaOnClick() {
    this.warning('Peça ao seu gestor para que recupere a senha para você no cadastro de empresas no site de gestão do Bluve');
  }

  desabilitarBotoesLogin() {
    if (window.location.hostname === 'localhost') return false;

    return (!this.state.reCaptchaToken || this.state.disableLoginPermanently);
  }

  handleReCaptcha(token) {
    let disableLogin = token ? false : true;

    this.setState({
      disableLogin,
      reCaptchaToken: token
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }
      this.setState({ loading: true });
      const isOk = await this.autenticar(values.login, values.password);
      this.setState({ loading: false });
      if (isOk) {
        history.push(`/fila`);
        history.go();
      }
    });
  }

  handleFormFinish = async values => {
    this.setState({ loading: true });


    if (window.location.hostname !== 'localhost') {
      const { reCaptchaToken } = this.state;
      const reCaptchaIsOk = await api.validateReCaptcha(reCaptchaToken);

      if (!reCaptchaIsOk) {
        this.setState({
          loading: false,
          disableLoginPermanently: true
        });
        this.warning('Falha no catpcha');
        return;
      }
    }

    const userAuthenticated = await this.autenticar(values.login, values.password);

    this.setState({ loading: false });

    if (userAuthenticated) {
      history.push(`/fila`);
      history.go();
    }
  }

  handleFinishFailed = ({ errorFields }) => {
    // this.props.form.scrollToField(errorFields[0].name);
  };

  render() {
    return (
      <div>
        <div className="header">
          <img
            src={'https://storage.googleapis.com/bluve-bucket/logo_branca.png'}
            alt='logo-bluve'
          />
          <div class="arrow">
            <span></span><span></span><span></span><span></span><span></span>
            <span></span><span></span><span></span><span></span><span></span>
            <span></span><span></span><span></span><span></span><span></span>
            <span></span><span></span><span></span><span></span><span></span>
            <span></span><span></span><span></span><span></span><span></span>
          </div>
        </div>
        <div className='telaMeio'>
          <p className='textoLogin'>Faça Login na Fila da Vez para gerenciar sua Loja</p>
          <Form onFinish={this.handleFormFinish} onFinishFailed={this.handleFinishFailed} className="login-form">
            <Form.Item
              initialValue={params.get('login')}
              name='login'
              rules={[{ required: true, message: 'Informe o login da loja' }]}
            >
              <Input
                // prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Login da Loja"
              />
            </Form.Item>

            <Form.Item name='password' rules={[{ required: true, message: 'Informe a senha de login' }]}>
              <Input.Password
                // prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Senha"
              />
            </Form.Item>

            <Form.Item>
              <ReCAPTCHA
                className='reCaptcha'
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={this.handleReCaptcha}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={this.state.loading}
                disabled={this.desabilitarBotoesLogin()}
              >
                Login
              </Button>
            </Form.Item>

            <Form.Item>
              <a className="EsqueciSenhaAdmin" href="# " onClick={this.esqueciSenhaOnClick}>Esqueci minha senha</a>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default Login;