import React from 'react';
import { useDrag } from 'react-dnd';
import { Avatar, Card, Tag, Button, Icon } from "antd";
import IconFont from '../../iconFont/IconFont';
import CustomDateFNS from '../../custom-date-fns/CustomDateFNS.container';
import { ArrowUpOutlined } from '@ant-design/icons';

const DraggableCard = ({ vendedor, index, btnEntrarNaFilaCallBack }) => {

    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => ({ index }),
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
            item: monitor.getItem(),
            didDrop: monitor.didDrop(),
        }),
        cancel: (props, monitor) => monitor.getItem(),
    });

    const opacity = isDragging ? 0.5 : 1;
    const backgroundColor = isDragging ? 'lightgreen' : 'white';
    const display = isDragging ? 'none' : 'block';
    const transition = 'all 0.3s ease';
    const transform = isDragging ? 'rotate(4deg)' : 'rotate(0deg)';

    const handleAvatarContextMenu = (e) => {
        e.preventDefault();
    };

    const btnEntrarNaFila = (vendedor) => {
        if (btnEntrarNaFilaCallBack) btnEntrarNaFilaCallBack(vendedor);
    }


    return (
        <div ref={drag} style={{ opacity, backgroundColor, transition, transform }}>
            <Card 
                className='fila-footer-carousel-card' key={index} hoverable style={{ display }}
            >
                <div className='fila-footer-carousel-card-content' onTouchStart={() => { }} style={{ touchAction: 'manipulation' }}>
                    <Avatar
                        src={vendedor.avatar}
                        size={42}
                        className={`fila-footer-carousel-card-avatar`}
                        onContextMenu={handleAvatarContextMenu}
                    />
                    <Button 
                        className='fila-footer-corousel-botao-up' 
                        icon={<ArrowUpOutlined />}
                        onClick={() => btnEntrarNaFila(vendedor)}
                    ></Button>
                    <br></br>
                    <label>{vendedor.apelido.split(' ')[0]}</label>
                    <br></br>
                    <hr className='fila-footer-carousel-card-divider' />

                    <Tag
                        className='fila-footer-carousel-card-tag'
                        color={`${vendedor.status.descricao === 'casa' ? "red" : "green"}`}
                    >
                        <IconFont
                            type={vendedor.status.icon}
                            size={14}
                            className='fila-footer-carousel-card-icon'
                        />
                        {vendedor.status.descricao}
                    </Tag>
                    <CustomDateFNS classCss='fila-footer-carousel-card-timer' date={vendedor.status.data} interval={15}></CustomDateFNS>
                </div>
            </Card>
        </div>
    );
};

export default DraggableCard;
