import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/performance";
import "firebase/analytics";
import "firebase/auth";
import "firebase/storage";

const DEV_PROJECT = false;
const USE_LOCAL_API = false;
let config;

if (!DEV_PROJECT) {
  config = {
    apiKey: "AIzaSyD6382EgeegLjTkKkXmMBnVxoDjamSTk9c",
    authDomain: "sira-41b32.firebaseapp.com",
    databaseURL: "https://sira-41b32.firebaseio.com",
    projectId: "sira-41b32",
    storageBucket: "sira-41b32.appspot.com",
    messagingSenderId: "322901096086",
    appId: "1:322901096086:web:0fc268bc95a5545d9774b7",
    measurementId: "G-QZM4CS2LWB",
  };
} else {
  config = {
    apiKey: "AIzaSyBb8MxslSLv4AnOhWFH2vSJuxMoEi5EeSk",
    authDomain: "bluve-4c-teste.firebaseapp.com",
    databaseURL: "https://bluve-4c-teste.firebaseio.com",
    projectId: "bluve-4c-teste",
    storageBucket: "bluve-4c-teste.appspot.com",
    messagingSenderId: "219758613774",
    appId: "1:219758613774:web:369b210dbaf37132c6735f",
    measurementId: "G-ZCPXRYKP6S",
  };
}

export const firebaseImpl = firebase.initializeApp(config);
const perf = firebase.performance();

// Só chamo o Analytics em ambiente oficial! ;-)
if (!DEV_PROJECT) {
  firebase.analytics();
}

function getProject() {
  const project = DEV_PROJECT ? 'bluve-4c-teste' : 'sira-41b32';
  if (USE_LOCAL_API) {
    return `http://localhost:5001/${project}/us-central1/api/v2`;
  }
  return `https://us-central1-${project}.cloudfunctions.net/api/v2`;
}


export const firebaseStorage = firebase.storage();
export const fbDatabase = firebase.firestore();
export const fbFirestore = firebase.firestore;
export const AUTH_URL = `${getProject()}/authUser`;
export const performance = perf;
export const ROUTER_API_FILA = `${getProject().replace('api', 'apiFila').replace('/v2', '')}`
export const RECAPTCHA_SITE_KEY = '6LepJzQjAAAAAMWEKCNCoz-5PegBgPCoGMOPDWvc';
