import React from 'react';
import { Timeline } from 'antd';
import './Timeline.css';

const TimeLineAtendimento = ({ timelineData }) => {
    return (
        <div className='div-timeline-pai'>
            <Timeline className='timeline-timeline'>
                {timelineData.map((item, index) => (
                    <Timeline.Item key={index} color={item.color}>
                        {item.value}
                    </Timeline.Item>
                ))}
            </Timeline>
            <br></br>
            <br></br>
            <label className='timeline-label'>Confirma?</label>
        </div>
    );
}

export default TimeLineAtendimento;